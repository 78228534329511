@import '../../theme/includes.scss';

.breadcrumb-icon {
  height: 48px;
  width: 48px;
  margin-bottom: $smallSpace;
}

.breadcrumb-icon:hover,
.breadcrumb-icon:focus,
.breadcrumb-icon:active {
  color: $themeDarkText;
  background-color: $lightContainerBg;
  border-color: $themeBorderColor;
}

.breadcrumb-heading {
  color: #000000;
  margin-bottom: 4px;
}

.breadcrumb-link a {
  color: $themeColor6;

  width: '88px';
  height: '24px';
}

.ant-breadcrumb li:last-child span {
  color: $themeMediumText;
}
.ant-breadcrumb-separator {
  color: $themeMediumText;
}
.breadcrumb-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.breadcrumb-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  flex: 0 0 48px;
  max-width: 48px;
}

.breadcrumb {
  padding-left: 24px;
}

// recipeUploadBtn css

.recipeBtn-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
}
.recipeBtn-wrapper .recipeUploadBtn:not(:first-child) {
  background: #febd2f;
}
.recipeUploadBtn {
  font-family: 'ClashDisplay-Medium';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #101010;
  padding: 12px 23px;
  background: #d3d0cf;
  border-radius: 100px;
}

@media screen and (max-width: 1024px) {
  .recipeUploadBtn {
    font-size: 12px;
    padding: 8px 11px;
  }
}
.breadcrumbMainWrapper {
  justify-content: space-between;
}
.breadcrumb-wrapper {
  display: flex;
}

.uploadMarketListButton {
  margin-right: 10px;
}

.taggedSupplierButton {
  background: #febd2f;
  border-color: transparent;
  height: 44px;
  padding: 4px 16px;
  font-size: 16px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'ClashDisplay-Regular';
  cursor: pointer;
  gap: 5px;
}

.supplyIngredientBreadcrumbDropdown {
  margin-right: 10px;
}

.ingredients-button-container {
  display: flex;
  align-items: center;
}

.radio-button-breadcrumb {
  padding-bottom: 10px;
}

.breadcrumb-switch {
  margin-top: 1.2rem;
}

// .ant-switch {
//   background-color: #febd2f !important;
// }

.ant-switch:focus:hover, .ant-switch-checked:focus, .ant-switch-checked:hover {
    border-color: #febd2f;
}

.ant-switch-handle {
  top: 10px;
}

// .ant-switch-checked {
//   background-color: #e97b71 !important;
// }

.ant-switch-inner {
  color: grey;
}
