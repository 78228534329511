@import '../../theme/includes';

.sub-wizard-form-group {
  margin: auto;
  position: relative;
}
.sub-wizard-form-group label {
  font-family: 'Sora-Regular';
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #212121;
  padding-bottom: 8px;
  display: block;
}
.sub-wizard-form-group label span {
  color: #f90101;
}
.sub-wizard-form-group input {
  width: 100%;
  padding: 12px 16px;
  gap: 4px;
  background: #ffffff;
  border: 1px solid #d3d0cf;
  /* box-shadow: 0px 0px 0px 2px rgba(65, 91, 231, 0.2); */
  border-radius: 10px;
  font-size: 14px;
  // width: 560px;
  border-radius: 10px;
}

ul.variation-dropdown-list {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 100%;
  background: #ffffff;
  border: 1px solid #d3d0cf;
  box-shadow: 0px 12px 40px 2px rgba(84, 84, 84, 0.12);
  border-radius: 10px;
  list-style: none;
  padding-left: 0;
  margin-top: 5px;
  margin-bottom: 0;
  z-index: 100;
  width: 560px;
  height: 160px;
  overflow: scroll;
}
ul.variation-dropdown-list > li:first-child p {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin: 0;
}
ul.variation-dropdown-list li h3 {
  font-family: 'Sora-SemiBold', 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-bottom: 0;
}
.sub-wizard-form-group ul.subRecipe-subMenu {
  list-style: none;
  padding-left: 0;
  max-height: 315px;
  min-height: 100px;
  overflow: auto;
}
ul.subRecipe-subMenu li {
  padding: 11px 45px 11px 11px;
}

.subRecipe-dropdownPlaceholder {
  color: #a5a5a5;
  margin-bottom: 0;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
}

ul.subRecipe-subMenu li p {
  margin-bottom: 0;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
}
.subRecipe-subMenu > li {
  position: relative;
}

li.subRecipe-dropdown-create-category {
  position: relative;
  height: 42px;
}

li.subRecipe-dropdown-create-category button {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: none;
  background: #000;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}
