@import '../../theme/includes';

// .container {
//   padding: $mediumSpace;
// }

.centerLoader {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10rem 0;

  div {
    margin-top: 0.5rem 0;
  }
}

.placeholderText {
  font-family: 'ClashDisplay-Regular';
  letter-spacing: 0.5px;
  font-size: $extraLarge;
  opacity: 0.5;
}

.profileCompletionBox {
  z-index: 1;
  justify-content: center;
  text-align: center;
  position: absolute;
  padding: 11rem 0rem;
  width: 100%;
  background: rgb(255 255 255 / 50%);
}
