@import '../../theme/includes';

.ant-layout-content {
  min-height: 100vh;
}
.Dashboard .site-card-border-less-wrapper {
  display: flex;
  gap: 10px;
  margin: 0 0 10px 10px;
  // flex-wrap: wrap;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #f6a118 !important;
}
.Dashboard
  .selector
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 44px;
  width: 252px;
  border-radius: 10px;
}
.Dashboard .selector .ant-picker {
  height: 44px;
  width: 252px;
  border-radius: 10px;
}
.Dashboard .selector {
  display: flex;
  gap: 16px;
  margin: 0 0 20px 30px;
}
.Dashboard .site-card-border-less-wrapper .ant-card-body {
  height: 128px;
  width: 280px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
}
.Dashboard .items {
  display: flex;
  place-content: center;
}
.Dashboard .title {
  font-family: "Sora-Regular";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  /* text-align: center; */
  color: #a5a5a5;
  font-weight: 100;
}
.Dashboard .details {
  width: 100%;
  margin-left: 16px;
}
.Dashboard .salecard, .Dashboard .costcard, .Dashboard .stockcard, .Dashboard .bottomcard {
  display: flex;
  place-content: center;
}
.Dashboard .salecard .title, .Dashboard .costcard .title, .Dashboard .stockcard .title {
  font-size: 20px;
  line-height: 0px;
  color: grey;
  font-weight: 600;
}
.Dashboard .bottomcard .title {
  font-size: 20px;
  line-height: 0px;
  color: grey;
  font-weight: 600;
}
.Dashboard .sidecard .title {
  font-size: 16px;
  line-height: 0px;
  color: grey;
  font-weight: 600;
}
.Dashboard .details .value {
  font-family: "Sora-Regular";
  font-size: 20px;
  line-height: 40px;
  letter-spacing: -0.02em;
  margin-top: 5px;
  /* text-align: center; */
  color: #212121;
}
.Dashboard .costcard-value {
  display: flex;
  place-content: center;
  font-size: 20px;
  line-height: 2vh;
  margin-top: 5px;
  color: grey;
}
.Dashboard .sub-title {
  display: flex;
  place-content: center;
  font-size: xx-large;
  line-height: 60px;
  margin-top: 5px;
  color: grey;
}
.order-sub-title {
  font-size: xx-large;
  line-height: 50px;
  margin-top: 10px;
  color: grey;
}
.Dashboard .sidecard-value {
  display: flex;
  place-content: center;
  font-size: xx-large;
  line-height: 60px;
  margin-top: 10px;
  color: grey;
}
.Dashboard .sidecardwaste-value {
  line-height: 4.25vh;
}
.Dashboard .sidecard-subvalue {
  display: flex;
  place-content: center;
  font-size: 15px;
  color: grey;
}
.Dashboard .branchcard-subvalue {
  display: flex;
  place-content: center;
  margin-top: 5px;
  font-size: 11px;
  color: grey;
}
.ordercard {
  display: flex;
  place-content: center;
  font-size: 15px;
  color: green;
}
.donutcard .title {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  color: grey;
}
.Dashboard .salecard-value {
  display: flex;
  place-content: center;
  font-size: 20px;
  line-height: 15vh;
  margin-top: 30px;
  color: grey;
}
.Dashboard .button {
  font-family: 'Sora-SemiBold';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #f9ab01;
  margin-top: 20px;
  left: 67px;
  text-decoration: underline;
}
.Dashboard .antd-pro-charts-pie-pie .antd-pro-charts-pie-legend {
  min-width: 100px;
}
.Dashboard .Graphs {
  display: flex;
  gap: 16px;
  margin: 0 0 20px 30px;
}
.Dashboard .Graphs .ant-card-body {
  height: 310px;
  width: 380px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 24px 0;
}
.Dashboard .listTable {
  display: flex;
  gap: 16px;
  margin: 0 0 20px 30px;
}
.Dashboard .listTable .ant-card-body {
  // height: 353px;
  width: 375px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 10px 20px;
}
.Dashboard .listTable .ant-divider {
  margin: 5px;
  margin-top: 10px;
}
.Dashboard .listTable .ant-table-cell {
  padding: 10px 5px !important;
}

.Dashboard .listTable .header .heading {
  font-family: 'Sora-SemiBold';
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}
.Dashboard .listTable .header .listbutton {
  font-family: 'Sora-SemiBold';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #f9ab01;

  text-decoration: underline;
}
.Dashboard .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13x;
}

.Dashboard .listTable .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 5px 0 11px 0;
  text-align: start;
  overflow-wrap: break-word;
}

.Dashboard .listTable .ant-table-thead {
  display: none;
}
.Dashboard .Graphs .graphdetails {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 3rem;
}
.Graphs .heading {
  position: absolute;
  width: 95%;
  height: 24px;
  left: 16px;
  top: 16px;
  font-family: 'Sora-SemiBold';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  text-align: center;
}

.dashboardTabContainer {
    margin-left: 2rem;
    margin-bottom: 1rem;

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #2c2c2c;
      font-weight: bold;
    }

    .ant-tabs-ink-bar {
      background-color: #f6a118;
    }

    .ant-tabs-tab:hover {
      color: #f6a118;
    }

    .ant-picker:hover {
      border-color: #f6a118;
    }

    .dashboardDatePicker {
      input {
        text-align: center;
        font-size: 1rem;
        margin-top: 2px;
      }
    }

    .ant-picker-input > input:focus, .ant-picker-input > input-focused {
      border-color: #f6a118;
    }

    .ant-picker-focused {
      border-color: #f6a118;
    }

    .ant-picker-range .ant-picker-active-bar {
      background-color: #f6a118;
    }
}

.dashboard-top-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.ant-picker-preset > span {
  background: #f6a118 !important;
  color: #000000 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #f6a118 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #ffce7f !important;

}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #ffce7f !important;
}

.summaryContainer {
  margin: 0rem 0rem 1rem 2rem;
  border: 1px solid #f0f0f0;
  
  .ant-card-bordered {
    background-color: #f0f0f0;
    margin: 10px;
    border-radius: 10px;
  }
  
  p {
    margin-left: 1rem;
    margin-top: 1rem;
  }
}

.TopBoxes {
  margin-top: 0.3rem;
  // margin-bottom: 0.3rem;
}

.TopBoxes > div {
  margin: 0rem 0.2rem;
}

.SideBoxes > div {
  margin: 0.2rem 0.2rem 0;
}

.SideBoxBottom > div {
  margin-top: 0.2rem;
}

.carditems img {
  width: 25px;
}

.dashboardcards {
  border-radius: 10px;
  height: 18.75vh;
  // border-color: #CFCDD6;
  background-color: #CFCDD6;
}
 
.dashboardcards-sidebar {
  display: flex;
  place-content: center;
  border-radius: 10px;
  // border-color: #CFCDD6;
}

.dashboardcards-sidebar-bottom {
  display: flex;
  place-content: center;
  border-radius: 10px;
  height: 17.50vh;
  // border-color: #CFCDD6;
  background-color: #CFCDD6;
}

.dot {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background-color: #F6A118;
  border-radius: 50%;
}

.superscript {
  vertical-align: super;
  font-size: 11px;
}

.subscript {
  font-size: 10px;
  font-family: 'Sora-SemiBold';
}

.lineChart {
  width: 97%;
  border-radius: 10px;
  background-color: #CFCDD6;
}

.centercard {
  display: flex;
  justify-content: space-between;
}

.itemone {
  margin-top: 20px;
  font-weight: bold;
  color: #E84536;
}

.itemtwo {
  margin-top: 0px;
  font-weight: bold;
  color: #0A0558;
}

.itemthree {
  margin-top: 0px;
  font-weight: bold;
  color: #F6A118;
}

.itemfour {
  margin-top: 0px;
  font-weight: bold;
  color: #2f5f98;
}

.item-value-one {
  font-weight: bold;
  margin-top: 20px;
  color: black;
}

.item-value-two {
  font-weight: bold;
  margin-top: 0px;
  color: black;
}

.item-value-three {
  font-weight: bold;
  margin-top: 0px;
  color: black;
}

.item-value-four {
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: -20px;
  color: black;
}

.mt-2 {
  margin-top: 2rem;
}

.dashboard-container {
    margin-top: 0.3rem;
}

.centered-card {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  text-align: center; 
  height: 170px; 
  border-radius: 10px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.variance {
  height: 265px; 
}

.variance-bottom-section {
  width: 160px;
}

.card-section {
  margin: 2px; 
}

.card-title {
  color: #E84536;
  font-size: 20px;
  font-weight: 900;
  text-shadow: 0.5px 0.5px 0.5px #000;
}

.sale-icon {
  margin-left: 3px;
}

.table-card-title {
  color: #2f5f98;
  font-size: larger;
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.card-title-graph {
  display: flex;
  justify-content: center; 
  align-items: center; 
  text-align: center;
  color: black;
  font-size: 20px;
  font-weight: 900;
  text-shadow: 1px 1px 1px #000;
}

.small-text {
  font-size: 0.8em;
  margin-right: 5px;
  font-family: 'Sora-SemiBold';
}

.large-text {
  font-size: 1.5em; 
  font-weight: bold; 
  margin-right: 2px;
}

.graph-card {
  border-radius: 10px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.pie-card {
  width: 495px;
  height: 260px;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0px;
}

.recharts-default-legend {
  display: none !important;
}

.recharts-cartesian-grid-horizontal, .recharts-cartesian-grid-vertical {
  display: none;
}

.dashboard-bt-card {
  margin-bottom: 10px;
  border-radius: 10px;
  width: 1207px;
}

.dashboard-bottom-cards {
  border-radius: 10px;
  margin-right: 5px;
}

.no-scroll {
  overflow-y: hidden !important;
}

.no-data-graph {
  display: flex;
  justify-content: center; 
  align-items: center; 
  text-align: center;
  font-weight: 600;
  width: 460px;
  height: 180px;
}

.card-header {
  display: flex;
  justify-content: left;
  align-items: center;
}

.menu-count {
  padding-top: 5px;
  padding-left: 5px;
  font-size: 14px; 
  color: gray; 
}

.eye-icon-container {
  position: absolute;
  top: 10px; 
  right: 10px; 
  cursor: pointer;
}

.eye-icon {
  width: 20px; 
  height: 20px;
}
