.Modal {
  margin: 0px 0px 20px 0px;
}
.Modal .case {
  width: 432px;
  height: 150px;
}

.dropdownUnitColorRed {
  border: 1px solid #ff303c !important;
}

.CategoryModalwizardLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}
