@import '../../theme/includes';

.search-wrapper {
  display: flex;
  gap: 24px;
  justify-content: flex-end;
}
.search-box {
  max-width: 349px;
  width: 100%;
}

.search-wrapper .gridIconContainer {
  margin-left: 0;
}

.title {
  font-family: 'Sora-SemiBold';
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #101010;
}

.loader-wrapper {
  height: 684px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.font {
  font-family: 'Sora-SemiBold';
}

.cellSpacingModifier {
  margin-bottom: 8px;
}

.tableColModifier {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
}

.col4Modifier {
  width: 120px;
}

.dotsModifier {
  width: fit-content;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f4f7;
  border-radius: 50px;
  cursor: pointer;
}

.dotOptionCategory {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Sora-Regular';
}

.tableTitleCategory {
  display: flex;
  align-items: center;
  font-family: 'Sora-Regular';
  font-weight: 600;
}
.titleArrowCategory {
  margin: 0px 5px;
  cursor: pointer;
}

.stepperButon {
  margin: '0 8px';
}

.brandDiv {
  font-size: 24px;
  margin: 5% 0% 2% 2%;
}

.listingTopFilter {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
}

.menu-container-without-paading {
  padding: 0px !important;
}

.rd-category-list-menu-container {
  padding: 28px 24px 16px 24px;
  display: flex;
  gap: 8px;
  position: relative;
}
.rd-category-list-menu-container .menu {
  margin: 0;
  padding: 0px 7px;
  gap: 4px;
  min-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  background: #efece6;
  border-radius: 100px;
}
.rd-category-list-menu-container .menuLight {
  min-width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0px 7px;
  border: 1px solid #d3d0cf;
  background: white;
  border-radius: 100px;
  transition: all 0.3s;
}
.rd-category-list-menu-container .menuLight:last-child {
  justify-content: center;
}
.rd-category-list-menu-container .menuLight:hover {
  cursor: pointer;
  background: #efece6;
  margin-top: -6px;
}
.rd-category-list-menu-container .text {
  font-family: 'ClashDisplay-Regular';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #101010;
}
// .rd-category-list-menu-container .menuContainer {
//   max-width: 1100px;
// }
.rd-category-list-menu-container .menuDark {
  min-width: 180px;
  justify-content: center;
}

// .ant-spin-nested-loading {
//   position: relative;
//   height: 680px;
//   overflow: auto;
// }
@media screen and (max-width: 1440px) {
  .search-wrapper .gridIconContainer {
    margin-right: 16px;
  }
  .rd-category-list-menu-container .menuContainer {
    padding: 28px 24px 16px 24px;
    display: flex;
    gap: 8px;
    position: relative;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    max-width: 1100px;
  }
}
@media screen and (max-width: 1024px) {
  .search-wrapper .gridIconContainer {
    margin-right: 16px;
  }
  .rd-category-list-menu-container .menuContainer {
    padding: 28px 24px 16px 24px;
    display: flex;
    gap: 8px;
    position: relative;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    max-width: 900px;
  }
}
@media screen and (max-width: 991px) {
  .search-wrapper {
    margin-top: 20px;
  }
}
.menuCategoryPage
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.menuCategoryPage .ant-table-tbody > tr > td:first-child {
  padding-left: 16px !important;
}
.menuCategoryPage .ant-table-tbody > tr > td:first-child > div {
  text-align: left;
}
