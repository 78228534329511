@import '../../theme/includes.scss';

.forgetPassword-form {
  display: flex;
  flex-direction: column;
  padding: 0 3rem;

  input,
  .ant-input-password {
    padding: 8px 10px;
    border-radius: 8px;
  }

  .login-btn {
    width: 100%;
  }
}

.spacing {
  margin-bottom: 20px;
}

.textRight {
  text-align: end;
}

.leftSideForForgotPassword {
  background-color: $pagecontentBlockColor;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-shadow: 0 0 10px 5px #ebebeb;
  border-radius: 20px;

  .ant-form-item {
    margin-bottom: 10px;
  }
  .sideLogoContainer {
    display: flex;
    justify-content: center;
    
    .sideLogo {
      width: 50%;
      margin: 2rem 0;
    }
  }
}

.sideLogo {
  // margin: 4rem;
}

.rightSide {
  background-color: #ffdd94;
  display: flex;
  justify-content: center;
  height: -webkit-fill-available;
}

.successIcon {
  color: $success;
}

.failedIcon {
  color: $error;
}

.success {
  color: green !important;
  margin-top: 20px;
}

.error {
  color: red;
  margin-top: 20px;
}

.b {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
