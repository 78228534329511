@import '../../theme/includes';

.ExportBtn {
  height: 48px;
  // margin-left: 10px;
}

.view-align-adjustment {
  justify-content: center;
}

.view-align-brand {
  justify-content: flex-start !important;
}

.sub-view-align-adjustment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

body[dir='rtl'] .sub-view-align-adjustment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

body[dir='rtl'] .subTextCol1 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Brands-list-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child
  .tableTitle {
  justify-content: flex-start;
  width: 450px !important;
}
