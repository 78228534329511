@import '../../theme/includes.scss';

.page-content {
    position: relative;
    left: var(--page-header-width);
    width: calc(100% - var(--page-header-width));
    min-height: 100vh;
    padding: 30px;
  }

.page-unaccessible {
  cursor: not-allowed !important;
}
