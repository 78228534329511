.wizardSubContainer-wrapper {
  margin-top: 300px !important;
}

.wizardSubContainer {
  width: 100%;
}
.recipeBuilderWizardStepTwo .customWizard {
  height: auto;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.recipeBuilderWizardStepTwo .customWizard::-webkit-scrollbar {
  display: none;
}
.wizardDropdown {
  width: 560px;
}

.step2-head {
  margin: 24px 0px;
  width: 100%;
  font-family: 'Sora-SemiBold';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
}

.step2Dropdown .ant-dropdown-menu {
  width: 560px;
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 10px;
  outline: none;
}

.dropdownTitle {
  font-family: 'Sora-Regular';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #a5a5a5;
}

.wizardDropdown
  > .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 44px;
  border-radius: 10px;
}

.wizardSubContainer .ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 280px;
}

.wizardSubContainer
  > .ant-select-single
  .ant-select-selector
  .ant-select-selection-search-input {
  width: 560px;
  border-radius: 10px;
  padding: 12px 16px;
  font-size: 14px;
}

.checkBox {
  margin: 24px;
}

.checkBox > .ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0px 26px 0px 0px;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}

.wizardSubContainer > .ant-input-lg {
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 10px;
}

.wizardSubContainer > .ant-select-lg {
  font-size: 14px;
}

// .sterik {
//   color: #f90101;
// }

.wizardSubContainer
  > .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 560px;
  border-radius: 10px;
}

.wizardDropdown > .ant-select-lg {
  font-size: 14px;
}

.gramContainer {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-bottom: 8px;
  position: relative;
}
.recipeBuilderWizardStepTwo .wizardSubContainer .gramContainer {
  justify-content: flex-start;
}
.recipeBuilderWizardStepTwo .wizardSubContainer .gramContainer .deleteButon {
  margin-left: 15px;
  position: static;
}
.gramSubContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  padding: 12px 0px 12px 16px;
  height: 44px;
  border: 1px solid #d3d0cf;
  border-radius: 0px 10px 10px 0px;
}

.gramBox {
  padding: 12px 0px 12px 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 106px;
  height: 42px;
  background: #f5f3ef;
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
}
.gramBoxWrapper {
  position: relative;
}
.unitsContainer {
  position: absolute;
  background: #fff;
  z-index: 1;
  box-shadow: inset 10px 11px 13px -5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 10px 11px 13px -5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.dropdownOptions {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Sora-Regular';
  padding: 5px 15px;
}

.unitsSubContainer {
  margin: 5px;
  border-bottom: 1px solid #dedede;
  padding: 14px 40px 0px 12px;
  cursor: pointer;
}

.wizardSubContainer .ant-upload.ant-upload-drag .ant-upload {
  padding: 0px 0px;
}

.label-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  width: 100%;
}

.formgroup {
  display: flex;
  justify-content: space-between;

  border: 1px solid #e1e1e1;
  border-radius: 10px;
  flex-wrap: wrap;
}

.formgroup label {
  background: #fbfaf9;
  border: 1px dashed #dddddd;
  border-radius: 5px;
  font-size: 16px;
  color: #000;
  width: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 280px;
}
.formgroup input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.label-wrapper p {
  font-family: 'Brown';
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  color: #999999;
}

.deleteButton {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.butonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px;
  gap: 8px;
  width: 560px;
}

.text-Area {
  border-radius: 10px;
  width: 100%;
}

// .wizard-button {
//   display: flex;
//   padding: 12px 16px;
//   gap: 12px;
//   width: fit-content;
//   height: 48px;
//   border-radius: 100px;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
// }

.gramValue {
  border: none;
  width: 75px;
  padding: 0px 10px 0px 0px;
}

.gramValue:focus-visible {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.addButton {
  width: 100%;
  height: 24px;
  font-family: 'ClashDisplay-Medium';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
}

.deleteButon {
  position: absolute;
  right: -30px;
  cursor: pointer;
}

.butonGroupDialog {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px;
  gap: 8px;
  width: 435px;
  margin-bottom: 16px;
}

.DialogHeight {
  height: 196px;
}

.deleteDialogText {
  margin: 0px 0px 32px 0px;
}

.add-ingredient-label-container {
  position: relative;
  display: flex;
  align-items: center;
}

.calorie-container-each-ingredient {
  margin-left: 15px;
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.calorie-each-value-container {
  background: #f5f3ef;
  padding: 8px;
  border-radius: 10px;
  width: 70px;
}

.popUp-recipe-Container {
  width: 500px;
}

.popUp-recipe-Container div div canvas {
  display: inline-block;
  vertical-align: middle;
  cursor: default;
  width: 500px !important;
  height: 278px !important;
}

.popUp-recipe-Container div div .g2-html-annotation {
  // width: 90px !important;
  font-weight: 900 !important;
  font-size: 20px !important;
}

.addButton-subContainer {
  width: fit-content;
  cursor: pointer;
}

.step2-calorie-label {
  display: flex;
  align-items: center;
  gap: 30rem;
}
