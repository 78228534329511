.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 0px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  padding-bottom: 15px;
}

.gifWidth {
  width: 200px;
}

.responseDialogContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  min-width: 380px;
}

.messageText {
  font-family: 'Sora-Regular';
  font-size: 16px;
}

.info {
  color: #1677ff;
}

.comingSoon {
  color: #1677ff;
}

.success {
  color: #53c31b;
  margin-bottom: 0px;

  margin-top: 10px;
  text-align: center;
}

.responseDialogTextSpacing {
  margin-bottom: 0px !important;
  margin-top: 10px !important;
  text-align: center !important;
}

.error {
  color: #ff4d4e;
}

.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
  border-radius: 10px;
  padding: 20px;
}

.errorTitle {
  font-family: 'Sora-Regular';
  font-size: 36px;
  margin-left: -20px;
}

.successImage {
  width: 150px;
  height: 150px;
}

.responseButton {
  border-radius: 32px;
  height: 44px;
}

