.CountStock-list-counter {
  font-family: 'Sora-Regular';
  font-size: 10px;
  letter-spacing: -0.02em;
  color: white;
  background-color: #ff303c;
  min-width: 16px;
  border-radius: 100px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stockCountHeading {
  margin-left: 55px;
  padding: 24px 24px 0;
  display: flex;
  align-items: baseline;
  gap: 16px;
}

.brand-filter-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.brand-filter-container
  .ant-select-multiple
  .ant-select-selector
  .ant-select-selection-overflow {
  flex-wrap: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Hide scroll bar for IE and Edge */
  scrollbar-width: none; /* Hide scroll bar for Firefox */
}

.dropdown-filter-container
  .ant-select-multiple
  .ant-select-selector
  .ant-select-selection-overflow::-webkit-scrollbar {
  display: none; /* Hide scroll bar for Chrome */
}

.brand-filter-container .ant-select-single .ant-select-selector {
  height: 44px !important;
  display: flex;
  align-items: center;
}

.brand-filter-container .ant-select-multiple .ant-select-selector {
  height: 44px !important;
  display: flex;
  align-items: center;
}

.stockCountHeading h1 {
  font-family: 'ClashDisplay-Medium';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
  margin: 0;
}

.stockCountHeading .heading {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #8a817e;
  margin-bottom: 0px;
}

.CountStock-list-large-counter {
  padding: 2px;
}
.CountStock-list-menu-style {
  font-family: 'ClashDisplay-Regular';
}

// .CountStock-list-wrapper
//   .ant-table-container
//   table
//   > thead
//   > tr:first-child
//   th:first-child
//   .tableTitle {
//   justify-content: flex-start;
// }

.CountStock-list-menu-container {
  padding: 0 24px 16px 24px;
  display: flex;
  gap: 8px;
  position: relative;
}
.CountStock-list-menu-container .menu {
  margin: 0;
  padding: 0px 7px;
  gap: 4px;
  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  background: #efece6;
  border-radius: 100px;
}
.CountStock-list-menu-container .menuLight {
  min-width: 160px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0px 7px;
  border: 1px solid #d3d0cf;
  background: white;
  border-radius: 100px;
  transition: all 0.3s;
}
.CountStock-list-menu-container .menuLight:last-child {
  justify-content: center;
}
.CountStock-list-menu-container .menuLight:hover {
  cursor: pointer;
  background: #efece6;
  margin-top: -6px;
}
.CountStock-list-menu-container .text {
  font-family: 'ClashDisplay-Regular';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #101010;
}

.CountStock-cellSpacing {
  text-align: left !important;
}

.stockCount-editButton {
  display: flex;
  /* padding: 0px 0px; */
  gap: 10px;
  width: 75px;
  height: 40px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

// .stockCount-editButton .ant-spin {
//   margin-top: 5px;
// }
.stockCount-storageInput .ant-input {
  height: 40px;
  border-radius: 10px;
  width: 50%;
}

.stockCount-loader-enable {
  background-color: #ffedc7;
}

.stockCount-alignment-left {
  text-align: left;
}

.stockCount-alignment-center {
  text-align: center;
}

.stockCount-override-column2-style {
  height: 20px;
  font-family: 'Sora-Regular' !important;
  font-size: 16px !important;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
}

.stock-count {
  padding-bottom: 10px;
}
