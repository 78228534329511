.wizardContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 700px;
  font-family: 'Sora-Regular';
  max-width: 560px;
  margin: auto;
}
.uploadimageblock .recipeWizardStepperContainer.wizard {
  width: 48%;
}
.butonGroup {
  margin-top: 15px;
}

.productionPlan-recipe-selection {
  width: 100%;
}

.productionPlan-dropdown {
  position: absolute;
  // left: 0%;
  // right: 0%;
  // top: 100%;
  background: #ffffff;
  border: 1px solid #d3d0cf;
  box-shadow: 0px 12px 40px 2px rgba(84, 84, 84, 0.12);
  border-radius: 10px;
  list-style: none;
  height: 300px;
  overflow: auto;
  padding-left: 0;
  margin-top: 5px;
  margin-bottom: 0;
  z-index: 99999;
  width: 35rem;
  padding: 10px;
}

.productionPlan-dropdown li {
  cursor: pointer;
  font-size: 14px;
}

.productionPlan-recipe-selection .ant-input {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  font-size: 14px;
}

.productionPlan-dropdown .li-label {
  margin-bottom: 1em;
}

.wizardContainer .productionPlanCollapseContainer {
  width: 100%;
  margin: 10px;
  position: relative;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // flex-direction: column;
}

.wizardContainer .productionPlanCollapseContainer .main-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wizardContainer
  .productionPlanCollapseContainer
  .ant-collapse-item
  .ant-collapse-header {
  font-family: 'Sora-SemiBold';
  font-size: 14px;
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 20%;
  right: 2px;
}

.wizardContainer .productionPlanCollapseContainer .ant-collapse-item {
  border: 1px solid #dedede;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 95%;
}

.collapse-recipeField-container {
  display: flex;
  align-items: center;
}

.collapse-recipeField-container .recipeField-subContainer {
  width: 33.3%;
}

.collapse-recipeField-container .ant-input {
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  font-size: 14px;
}

// .panel-container {
//   border: 1px solid #dedede;
//   padding: 10px;
//   border-radius: 10px;
// }

.panel-recipe-name-field {
  width: 290px;
  border: 1px solid #dedede;
  height: 44px;
  border-right: none;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px 0px 0px 10px;
  overflow: hidden;
}

.panel-container .collapse-ingredient-label {
  font-size: 14px;
  margin: 8px;
  margin-left: 0px;
  font-family: 'Sora-Regular';
  font-weight: bolder;
}

.production-plan-recipe-delete-button {
  cursor: pointer;
  position: absolute;
  right: -35px;
  padding-top: 55px;
}

.disable-background-transparent input[type='number'][disabled] {
  background-color: transparent;
}
