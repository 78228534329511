.wizardSubContainer > .row {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.wizardSubContainer > .row > .col {
  width: 272px;
}

.parentContainer {
  margin-bottom: 20px;
}

.disableClass {
  pointer-events: none;
}

.stepOneaddButton {
  width: 100%;
  height: 24px;
  font-family: 'ClashDisplay-Medium';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  cursor: pointer;
}

.stepOneDisableaddButton {
  width: 100%;
  height: 24px;
  font-family: 'ClashDisplay-Medium';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  cursor: pointer;
  pointer-events: none;
}

.spacing {
  margin-top: 25px;
}

.Ingredient-head {
  align-self: flex-start;
  font-family: 'Sora-SemiBold';
  line-height: 55px;
  font-size: medium;
}

.recipeIngredient-head-bold {
  margin: 32px 0px 0px 0px;
  align-self: flex-start;
  font-family: 'Sora-SemiBold';
  font-size: 16px;
  font-size: medium;
}

.recipeIngredientSubContainer > .group {
  display: flex;
  border: 1px solid #d3d0cf;
  border-radius: 10px;
  overflow: hidden;
  height: 44px;
}

span.category > span {
  background: #f3f4f6;
  width: 100%;
  border: 1px solid #d9d9d9;
  display: block;
  line-height: 55px;
  border-radius: 10px;
  padding-left: 10px;
}
.recipeIngredient {
  position: relative;
  margin-bottom: 8px;
}
.recipeIngredientSubContainer span.recipeIngredient {
  width: 200px;
}

.recipewizardIngredientsLabel {
  position: relative;
  display: flex;
  margin: 24px 0px 8px 0px;
  align-items: center;
}
.recipewizardIngredientsLabel .recipeIngredientsLabel,
.recipeIngredientSubContainer span.recipeIngredient {
  width: 200px;
}
.recipeIngredientSubContainer .details {
  width: 60px;
}
.recipewizardIngredientsLabel span.label {
  width: 60px;
  padding-right: 10px;
}
.recipewizardIngredientsLabel span.label2 {
  width: 70px;
}
.recipewizardIngredientsLabel span.label3,
.recipeIngredientSubContainer .details2 {
  width: 118px;
}
.recipewizardIngredientsLabel span.label4 {
  width: 158px;
}
.recipeIngredientSubContainer .details3 {
  width: 122px;
}
.recipewizardIngredientsLabel span.label4,
.recipewizardIngredientsLabel span.label3,
.recipewizardIngredientsLabel span.label {
  text-align: center;
}
.group input {
  border: none;
  border-right: 1px solid #d3d0cf;
  padding: 12px 6px;
}
.details2 {
  width: 120px;
  display: flex;
  align-items: center;
  background: #f3f4f6;
}

.details2 span {
  width: 50%;
  text-align: center;
  border-right: 1px solid #d3d0cf;
  font-size: 14px;
}
.details2 div {
  width: 50%;
  padding-left: 10px;
}
.pricegroup {
  line-height: 44px;
  display: flex;
  align-items: center;
  border: 1px solid #d3d0cf;
  border-radius: 10px;
  overflow: hidden;
}
.pricegroup span {
  background: #d3d0cf;
  width: 25%;
  text-align: center;
  font-size: 14px;
}
.pricegroup div {
  padding-left: 10px;
  background-color: #f3f4f6;
  width: 75%;
}
.pricegroup input,
.finalpricegroup input {
  border: none;
  outline: none;
}
.finalpricegroup {
  line-height: 40px;
  border-radius: 10px;
  border: 1px solid #d3d0cf;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.finalpricegroup span {
  width: 25%;
  text-align: center;
  border-right: 1px solid #d3d0cf;
  background: #d3d0cf;
  font-size: 14px;
}

.deleterecipeIngredient {
  position: absolute;
  right: -25px;
  top: 12px;
}

.recipeIngredient-calorie-label {
  position: absolute;
  right: -65px;
  top: 5px;
  font-size: 14px;
}
