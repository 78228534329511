@import '../../theme/includes.scss';

.search-box {
  border-radius: 20px;
}

.search-box input {
  height: 30px;
}

.anticon-search svg {
  color: $themeLightText;
  height: 16px;
  width: 16px;
}
