.checkboxContainer {
  display: flex; 
  flex-wrap: nowrap; 
}

.checkboxLabel {
  margin-right: 20px; 
}

.checkboxContainer input {
  margin-right: 5px;
}

.date-time-inputs input, select {
  border-color: #728096;
  padding: 6.5px 11px;
  height: 44px;
  margin-top: 10px;
  margin-right: 40px;
  display: inline-block;
  border-radius: 12px;
}

.date-time-inputs label {
  margin-right: 5px;
  display: inline-block;
}

.ant-radio-inner {
  border-color: #728096;
}
.ant-radio-checked .ant-radio-inner {
    border-color: #f9ab01;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: #f9ab01;
}

.date-time-inputs input:hover,
.date-time-inputs input:focus {
  border-color: #f9ab01;
}

.time-select  {
  border-width: 2px;
}

/* Style the select element when it's focused */
.time-select:focus, .time-select:hover  {
  border-color: #f9ab01; 
  border-width: 2px;
}



