.feedBack .ant-modal-content {
  height: 815px;
  width: 480px;
  border-radius: 16px;
}

.feedBackDrawer-body .subContainer {
  width: 100%;
  margin: 18px 0 0 0;
}
.feedBackDrawer-body .textarea.ant-input.text-Area {
  height: 120px;
  width: 478px;
  left: 0px;
  top: 28px;
  border-radius: 10px;
  padding: 12px 16px 12px 16px;
}

// .feedBackDrawerContainer {
//   position: absolute;
//   // right: 0px;
//   top: 11%;
//   left: 50%;
//   z-index: 10;
//   display: flex;
//   align-items: center;
//   transform: translatex(-50%);
//   display: none;
//   // transform: translatex(100%);
// }
// .feedBackDrawerContainer2 {
//   display: block;
//   // transform: translatex(0);
// }
.feedBackDrawer-body .subContainer button {
  //   border-radius: 25px;
  background-color: #ffedc7;
  //   padding: 25px 20px;
  //   display: flex;
  //   align-items: center;
  //   border-right: none;
  //   margin-right: -1px;
  //   z-index: 10;
  //   position: absolute;
  //   left: -57px;
  height: 48px;
  width: 164px;
  border-radius: 100px;
  padding: 12px 16px 12px 16px;
  font-family: 'ClashDisplay-Regular';
  color: #a5a5a5;
  font-size: 16px;
  line-height: 24px;
  border: none;
  // margin-top: 25px;
}
.feedBackDrawer-body .subContainer .ant-btn:hover,
.feedBackDrawer-body .subContainer .ant-btn:focus {
  background-color: rgb(254, 189, 47);
  border-right: none;
}
// .feedBackDrawerContainer .feedBackDrawer::-webkit-scrollbar {
//   display: none;
// }
// .feedBackDrawerContainer .feedBackDrawer {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }
// .feedBackDrawerContainer .feedBackDrawer {
//   height: 750px;
//   //   max-width: 480px;
//   width: 480px;
//   // margin-right: 23px;
//   background: #ffffff;
//   border: 1px solid #febd2f;
//   box-shadow: 0px 4px 20px rgba(143, 131, 131, 0.16);
//   border-radius: 16px;
//   overflow: auto;
// }
// .feedBackDrawerContainer .feedBackDrawer .feedBackDrawer-body {
//   padding: 16px 24px;
// }
// .feedBackDrawerContainer .feedBackDrawer .feedBackDrawer-header {
//   font-family: 'Sora-SemiBold';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 16px;
//   line-height: 24px;
//   letter-spacing: -0.02em;
//   color: #212121;
//   padding: 24px;
//   border-bottom: 1px solid #e3ddd0;
//   padding-bottom: 16px;
// }
// .feedBackDrawerContainer .feedBackDrawer .feedBackDrawer-body .form-group {
//   padding-bottom: 16px;
//   max-width: 100%;
//   width: 100%;
// }
// .feedBackDrawerContainer
//   .feedBackDrawer
//   .feedBackDrawer-body
//   .form-group
//   input {
//   width: 100%;
//   border-right: 1px solid #d3d0cf;
//   padding: 12px 16px;
//   background: #ffffff;
//   border: 1px solid #d3d0cf;
//   border-radius: 10px;
//   height: 44px;
//   font-size: 14px;
// }

// .feedBackDrawerContainer .feedBackDrawer .feedBackDrawer-body .form-group span {
//   margin-left: 0px;
// }

// .feedBackDrawerContainer
//   .feedBackDrawer
//   .feedBackDrawer-body
//   .form-group
//   textarea {
//   height: 120px;
//   width: 100%;
//   border-right: 1px solid #d3d0cf;
//   padding: 12px 16px;
//   background: #ffffff;
//   border: 1px solid #d3d0cf;
//   border-radius: 10px;
//   font-size: 14px;
// }
// .feedBackDrawerContainer
//   .feedBackDrawer
//   .feedBackDrawer-body
//   .form-group
//   button {
//   font-family: 'ClashDisplay-Medium';
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 24px;
//   letter-spacing: -0.02em;
//   color: #a5a5a5;
//   background: #ffedc7;
//   border-radius: 100px;
//   opacity: 1;
//   padding: 10px 20px;
//   border: none;
//   margin-left: auto;
//   position: static;
// }

.feedBackDrawer-body .continue {
  //   background: #febd2f;
  margin-top: 25px;
  color: #000;
  height: 48px;
  width: 164px;
  border-radius: 100px;
  padding: 12px 16px 12px 16px;
  font-family: 'ClashDisplay-Regular';
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.feedBackDrawer-body .subContainer .feedback-imageUploader {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
}

.feedBackDrawer-body .subContainer .feedback-imageUploader label {
  background: #fbfaf9;
  border: 1px dashed #dddddd;
  border-radius: 5px;
  font-size: 16px;
  color: #000;
  width: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 280px;
}
.feedBackDrawer-body .subContainer .feedback-imageUploader input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.feedBackDrawer-body .subContainer .feedback-imageUploader .label-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.feedBackDrawer-body .subContainer .feedback-imageUploader .label-wrapper p {
  font-family: 'Brown';
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  color: #999999;
}

.feedBackDrawer-body .buttonSubmitSpacing {
  // margin-top: 64px;
  text-align: right;
}

.feedBackDrawer-body .subContainer .imageUpload {
  //   overflow: hidden;
  // height: 280px;
  width: 100%;

  text-align: center;
  background-color: #fbfaf9;
  border-radius: 10px;
  //   position: relative;
  border: 1px dashed #dddddd;
  // margin: 18px 0 0 0;
}
