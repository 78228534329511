.userListMenu .title {
  font-family: 'Sora-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-bottom: 16px;
}
.userListMenu .content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.userListMenu .content-text {
  width: 50%;
  margin-bottom: 24px;
}
.userListMenu .content-text p:first-child {
  font-family: 'Sora-SemiBold';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #a5a5a5;
  margin-bottom: 8px;
}
.userListMenu .content-text p:last-child {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #101010;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0;
}
li.content-list {
  width: 100%;
  padding: 11px 16px;
}
li.content-list:first-child {
  background: #febd2f;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
li.content-list:not(:first-child) {
  border-bottom: 1px solid #d3d0cf;
}
li.content-list p {
  font-family: 'Sora-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #212121;
  margin-bottom: 0;
  text-align: center;
}

.readOnlyPara {
  text-align: right !important;
  font-size: 8px !important;
}

.tableTickCrossIcons {
  text-align: center;
}

.readOnlyTextSize {
  font-size: 8px;
}

.readOnlyFontSize {
  font-size: 6px;
}
