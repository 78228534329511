.UserPersonalInfo {
  max-width: 560px;
  margin: auto;
}

.UserPersonalInfo .title {
  font-family: 'Sora-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-top: 16px;
  margin-bottom: 16px;
}
.UserPersonalInfo .action-buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px 0px;
}
.UserPersonalInfo .action-buttons-container .deactivate {
  color: #ff303c;
  display: flex;
  padding: 12px 16px;
  gap: 12px;
  width: -moz-fit-content;
  width: fit-content;
  height: 48px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #d3d0cf;
  font-family: 'ClashDisplay-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
.UserPersonalInfo .content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.UserPersonalInfo .content-text {
  width: 50%;
  margin-bottom: 24px;
}
.UserPersonalInfo .content-text p:first-child {
  font-family: 'Sora-SemiBold';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #a5a5a5;
  margin-bottom: 8px;
}
.UserPersonalInfo .content-text p:last-child {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #101010;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0;
}
li.userInfo-content-list {
  width: 100%;
  padding: 11px 16px;
}
li.userInfo-content-list:first-child {
  background: #febd2f;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
li.userInfo-content-list:not(:first-child) {
  border-bottom: 1px solid #d3d0cf;
}
li.userInfo-content-list p {
  font-family: 'Sora-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #212121;
  margin-bottom: 0;
  text-align: center;
}

.UserPersonalInfo-tableTickCrossIcons {
  text-align: center;
}

.userInfo-readOnlyFontSize {
  font-size: 6px;
}

.UserPersonalInfo .wizardDropdown .label-after-heading {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}

.UserPersonalInfo
  .wizardDropdown
  input.ant-input.ant-input-disabled.ant-input-lg {
  border-radius: 10px;
}


.configurationTabContainer {
  margin-left: 2rem;
  margin-bottom: 1rem;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2c2c2c;
    font-weight: bold;
  }

  .ant-tabs-ink-bar {
    background-color: #f6a118;
  }
  
  .ant-tabs-tab:hover {
    color: #f6a118;
  }

  .ant-steps, .wizard {
    display: none;
  }
}
