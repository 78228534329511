.loaderWidthHeight {
  width: 608px;
  height: 590px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ingredientDialog {
  margin: 0px 0px 20px 0px;
}

.ingredientSubContainer {
  width: 100%;
  margin: 0px 0px 24px 0px;
}

.ingredientSubContainer > .ant-input:placeholder-shown {
  text-overflow: ellipsis;
  border-radius: 10px;
  height: 44px;
}

.ingredientLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}

.UOMAllergen {
  display: flex;
  gap: 16px;
  align-items: center;
}

.spanWidth
  > .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 11px;
  border-radius: 10px;
}

// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   position: relative;
//   background-color: #fff;
//   border: 1px solid #d9d9d9;
//   border-radius: 10px;
//   transition: all 0.3s cubic-bezier(0.645, 0.045, 0.35);
// }

.spanWidth > .ant-select-lg {
  font-size: 14px;
}

.error {
  color: rgb(172, 11, 11);
}

.ant-modal-body .butonGroup {
  width: 100% !important;
}

.userAccessDialogContainer .ant-modal-body {
  width: 560px;
}

.ingredientSubContainer > .dropdownUnitColorRed .ant-select-selector {
  border: 1px solid #ff303c !important;
}
