@import '../../theme/includes';

.wizard-form-group {
  margin: auto;
  position: relative;
}
.wizard-form-group label {
  font-family: 'Sora-Regular';
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #212121;
  padding-bottom: 8px;
  display: block;
}
.wizard-form-group label span {
  color: #f90101;
}
.wizard-form-group input {
  width: 100%;
  padding: 12px 16px;
  gap: 4px;
  background: #ffffff;
  border: 1px solid #d3d0cf;
  /* box-shadow: 0px 0px 0px 2px rgba(65, 91, 231, 0.2); */
  border-radius: 10px;
  font-size: 14px;
  width: 560px;
  border-radius: 10px;
}

ul.dropdown-list {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 100%;
  background: #ffffff;
  border: 1px solid #d3d0cf;
  box-shadow: 0px 12px 40px 2px rgba(84, 84, 84, 0.12);
  border-radius: 10px;
  list-style: none;
  padding-left: 0;
  margin-top: 5px;
  margin-bottom: 0;
  z-index: 99999;
}
ul.dropdown-list > li:first-child p {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin: 0;
}
ul.dropdown-list li h3 {
  font-family: 'Sora-SemiBold', 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-bottom: 0;
}
.wizard-form-group ul.subMenu {
  list-style: none;
  padding-left: 0;
  max-height: 315px;
  min-height: 100px;
  overflow: auto;
}
ul.subMenu li {
  padding: 11px 45px 11px 11px;
}

.placeholder {
  color: #a5a5a5;
  margin-bottom: 0;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
}

ul.subMenu li p {
  margin-bottom: 0;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
}
.subMenu > li {
  position: relative;
}
.list-btn {
  position: absolute;
  right: 23px;
  height: 288px;
  overflow-y: scroll;
  z-index: 999;
  text-align: center;
  padding: 10px;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 24px;
    background-color: #d3d0cf;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }
}
.list-btn p {
  margin-bottom: 0;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #212121;
  width: 20px;
}
.list-btn .active {
  background: #f5f3ef;
  border-radius: 100%;
}
li.create-category {
  position: relative;
  height: 42px;
}

li.create-category button {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: none;
  background: #000;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}
.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  background-color: #212121;
}

.buttonStyling {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  background-color: #212121;
  border: none;
}

.optionIcon {
  padding-left: 10px;
}

.modeTwo {
  justify-content: space-between;
}

.mainWrapper {
  margin-top: 20px;
}

.selectStyle {
  width: 500px;
}

.dividerStyle {
  margin: 4px 0;
}
