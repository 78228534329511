.approvalRecipeDialogContainer .ant-modal-content {
  width: 685px;
}

.ingredientListMenu .title {
  font-family: 'Sora-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-bottom: 16px;
}
.ingredientListMenu .content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.ingredientListMenu .content-text {
  width: 50%;
  margin-bottom: 24px;
}
.ingredientListMenu .content-text p:first-child {
  font-family: 'Sora-SemiBold';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #a5a5a5;
  margin-bottom: 8px;
}
.ingredientListMenu .content-text p:last-child {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #101010;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0;
}
.ingredientListDialogAllergenTitle {
  margin-top: 24px;
}
// li.content-list {
//   width: 100%;
//   padding: 11px 16px;
// }
// li.content-list:first-child {
//   background: #febd2f;
//   border-top-left-radius: 20px;
//   border-top-right-radius: 20px;
// }
// li.content-list:not(:first-child) {
//   border-bottom: 1px solid #d3d0cf;
// }
// li.content-list p {
//   font-family: 'Sora-SemiBold';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 12px;
//   line-height: 18px;
//   letter-spacing: 0.5px;
//   text-transform: uppercase;
//   color: #212121;
//   margin-bottom: 0;
// }

// .readOnlyPara {
//   text-align: right !important;
//   font-size: 8px !important;
// }

// .tableTickCrossIcons {
//   text-align: center;
// }

.center-text-align-approval {
  text-align: center;
}

// .readOnlyTextSize {
//   font-size: 8px;
// }

// .readOnlyFontSize {
//   font-size: 6px;
// }

.approvalRecipeDialogContainer .ant-modal-content .ant-modal-body {
  width: 100% !important;
}

.approvalRecipeDialogContainer
  .ant-modal-content
  .ant-modal-body
  .borderApproveRecipeColor:hover,
.borderApproveRecipeColor:focus {
  color: #30a44c;
  border-color: #30a44c;
  border-radius: 10px;
  background: #fff;
}

.approvalRecipeDialogContainer
  .ant-modal-content
  .ant-modal-body
  .borderRejectRecipeColor:hover,
.borderRejectRecipeColor:focus {
  color: #f90101;
  border-color: #f90101;
  border-radius: 10px;
  background: #fff;
}

.updateButtonApproval {
  border-radius: 10px;
}

.approvalUpdateButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.approvalModalHeaderAlign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

// .approvalUpdateButtonContainer button,
// button > span {
//   font-family: 'Sora-SemiBold' !important;
// }

// .tableTickCrossIcons input {
//   border-radius: 8px;
//   width: 130px;
// }

.tableTickCrossIconsWithoutCenter input {
  border-radius: 8px;
  width: 130px;
}

.titleWithIconAlign {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ApprovalFieldsEditIcon {
  cursor: pointer;
}

.borderRejectRecipeColor {
  border: 1px solid #f90101;
}

.borderApproveRecipeColor {
  border: 1px solid #30a44c;
}

.categoryNameApprovalModal {
  width: max-content;
}

.dropdown-checkIcon-spacing {
  display: flex;
  align-items: center;
  gap: 12px;
}

.ingredient-selection-cancel-icon-color {
  color: #f90101;
}

.ingredient-selection-success-icon-color {
  color: #53ba23;
}

.ingredient-unmatach-select-icons {
  cursor: pointer;
}

.cancel-editted-icon-container {
  display: flex;
  align-items: center;
  gap: 18px;
}

.ingredient-unmatach-select-icons svg {
  width: 18px;
  height: 18px;
}

.recipe-name-container-width {
  width: 340px;
}

.unmatched-ingredients-danger-message {
  color: #f90101;
  text-align: justify;
}

.tableTickCrossIcons input {
  width: 70px;
  border-radius: 8px;
}
