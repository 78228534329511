.integrationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  margin: auto;

  img {
    width: 10%;
  }

  p {
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
  }
}
