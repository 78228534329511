.minimumheight {
  min-height: 350px;
}

.BranchTextArea {
  height: auto !important;
}

.wizardDropdown > .dropdownUnitColorRed .ant-select-selector {
  border: 1px solid #ff303c !important;
}
