.ingredient-list-counter {
  font-family: 'Sora-Regular';
  font-size: 10px;
  letter-spacing: -0.02em;
  color: white;
  background-color: #ff303c;
  min-width: 16px;
  border-radius: 100px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ingredient-list-large-counter {
  padding: 2px;
}
.ingredient-list-menu-style {
  font-family: 'ClashDisplay-Regular';
}

// .ingredient-list-wrapper
//   .ant-table-container
//   table
//   > thead
//   > tr:first-child
//   th:first-child
//   .tableTitle {
//   justify-content: flex-start;
// }

.ingredient-list-menu-container {
  padding: 28px 24px 16px 24px;
  display: flex;
  gap: 8px;
  position: relative;
}
.ingredient-list-menu-container .menu {
  margin: 0;
  padding: 0px 7px;
  gap: 4px;
  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  background: #efece6;
  border-radius: 100px;
}
.ingredient-list-menu-container .menuLight {
  min-width: 160px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0px 7px;
  border: 1px solid #d3d0cf;
  background: white;
  border-radius: 100px;
  transition: all 0.3s;
}
.ingredient-list-menu-container .menuLight:last-child {
  justify-content: center;
}
.ingredient-list-menu-container .menuLight:hover {
  cursor: pointer;
  background: #efece6;
  margin-top: -6px;
}
.ingredient-list-menu-container .text {
  font-family: 'ClashDisplay-Regular';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #101010;
}

.cellSpacingIngredientTable {
  text-align: left !important;
}

.unMatch-ingredient-empty > .dropdownUnitColorRed .ant-select-selector {
  border: 1px solid #ff303c !important;
}

.ingredientlist-column-font {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 16px !important;
}
