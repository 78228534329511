.SupplierIngredients-list-counter {
  font-family: 'Sora-Regular';
  font-size: 10px;
  letter-spacing: -0.02em;
  color: white;
  background-color: #ff303c;
  min-width: 16px;
  border-radius: 100px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.supplierHeading {
  margin-left: 55px;
  padding: 14px 24px 0;
  display: flex;
  align-items: baseline;
  gap: 16px;
}
.supplierHeading h1 {
  font-family: 'ClashDisplay-Medium';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
  margin: 0;
}

.supplierHeading .heading {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #8a817e;
  margin-bottom: 0px;
}

.SupplierIngredients-list-large-counter {
  padding: 2px;
}
.SupplierIngredients-list-menu-style {
  font-family: 'ClashDisplay-Regular';
}

.SupplierIngredients-list-wrapper
  .ant-table-tbody
  > tr
  > td:first-child {
    width: 35px !important;
  }

.SupplierIngredients-list-wrapper 
  .ant-table-tbody 
  > tr 
  > td:nth-child(4) {
    width: 300px !important;
  }

  .taggedUnit {
    width: 85px !important;
  }

  .SupplierIngredients-list-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child
  .taggedTitle {
  justify-content: flex-start;
  width: 480px !important;
}

  .SupplierIngredients-list-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:nth-child(4)
  .taggedCost {
  justify-content: center;
  width: 200px !important;
}

.untaggedStorageUnit {
  justify-content: center;
}

.mark-default {
  width: 200px;
  align-items: center !important;
}

.SupplierIngredients-list-menu-container {
  padding: 0 24px 1px 24px;
  display: flex;
  gap: 8px;
  position: relative;
}
.SupplierIngredients-list-menu-container .menu {
  margin: 0;
  padding: 0px 7px;
  gap: 4px;
  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  background: #efece6;
  border-radius: 100px;
}
.SupplierIngredients-list-menu-container .menuLight {
  min-width: 160px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0px 7px;
  border: 1px solid #d3d0cf;
  background: white;
  border-radius: 100px;
  transition: all 0.3s;
}
.SupplierIngredients-list-menu-container .menuLight:last-child {
  justify-content: center;
}
.SupplierIngredients-list-menu-container .menuLight:hover {
  cursor: pointer;
  background: #efece6;
  margin-top: -6px;
}
.SupplierIngredients-list-menu-container .text {
  font-family: 'ClashDisplay-Regular';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #101010;
}

.SupplierIngredients-cellSpacing {
  text-align: left !important;
}

.Supplier-ingredient-editButton {
  display: flex;
  /* padding: 0px 0px; */
  gap: 10px;
  width: 75px;
  height: 40px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

// .Supplier-ingredient-editButton .ant-spin {
//   margin-top: 5px;
// }
.storageFactorInput .ant-input {
  height: 40px;
  border-radius: 10px;
  width: 80%;
}

.loader-enable-supplier {
  background-color: #ffedc7;
}

.alignment-center-column-values {
  text-align: center;
}

.override-column-2-style {
  height: 20px;
  font-family: 'Sora-Regular' !important;
  font-size: 16px !important;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  border: none !important
}

.ant-select-item[title="No buying unit available"] .ant-select-item-option-content {
  color: grey;
  background-color: white;
}

.ant-select-item[title^="Create"] .ant-select-item-option-content {
  color: white;
  background-color: black;
}

