.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
}
.DialogWrapper .anticon svg {
  display: inline-block;
  color: #d3d0cf;
}

.ant-modal-body {
  // padding: 16px 8px 0px 24px !important;
}

.ant-modal-body .image {
  border-radius: 10px;
  overflow: hidden;
}

.modalBody {
  max-height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;
}

.modalBody::-webkit-scrollbar {
  width: 4px;
  background-color: #d3d0cf;
  height: 10vh;
}

/* Track */
.modalBody::-webkit-scrollbar-track {
  width: 4px;
  height: 10vh;
}

/* Handle */
.modalBody::-webkit-scrollbar-thumb {
  width: 4px;
}

.image {
  margin-bottom: 24px;
}
.subhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
}

.cover-photo {
  position: relative;
  display: flex;
  align-items: center;
  object-fit: contain;
}

.subRecipe-Head {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.print-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.subHead-left {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #101010;
  margin-top: 20px;
}

.subHead-right {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-left: 7px;
}

.block {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  max-width: 100%;
  height: 25px;
}
.block.block1 > div:first-child {
  width: 28%;
}
.block.block1 > div {
  width: 25%;
}
.block.block2 > .callories-text:first-child {
  width: 28%;
}
.block.block2 > .callories-text {
  width: 25%;
}
.block2 {
  align-content: space-between;
}

.protein {
  margin-left: 16px;
}

.step {
  font-weight: 100;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: black !important;
  margin: 16px 0px 8px 0px;
}

.step-point {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
}
.step-number {
  font-weight: 600;
}
.step-number-text {
  font-weight: 400;
  font-family: 'Sora-Regular';
  word-break: break-word;
  text-align: left;
  width: 90%;
  color: #a5a5a5 !important
}
.step-point.step-number.font {
  width: 5%;
  text-align: right;
  text-align: right;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Sora-SemiBold';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #a5a5a5 !important
}
.step-gap {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 8px;
}
.ingredient {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: black !important;
}

.block3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0px 8px 0px;
}

.dialog-ingredient-quantity {
  text-align: end;
}

.block4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block4-text {
  width: 280px;
  height: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #a5a5a5 !important;
  margin: 2px 0px;
}

.block4-weight {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #a5a5a5 !important;
  text-align: end;
}
.ingredient-text {
  height: 20px;
}

.variation-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.allergen {
  width: 80px;
  height: 18px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 16px 0px 12px 0px;
  color: black !important;
}

.allergen-text {
  width: 432px;
  height: 20px;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #a5a5a5 !important;
}

body[dir='rtl'] .additional {
  width: 125px;
  height: 18px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #a5a5a5;
  margin-top: 16px;
  text-align: right;
}

.additional {
  width: 125px;
  height: 18px;
  font-style: normal;
  font-weight: 100;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: black !important;
  margin-top: 16px;
}

body[dir='rtl'] .additional-text {
  width: 432px;
  height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-top: 8px;
  font-family: 'Sora-Regular';
  text-align: right;
}

.additional-text {
  width: 432px;
  height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #a5a5a5 !important;
  margin-top: 8px;
  font-family: 'Sora-Regular';
}

.callories-text {
  width: 53px;
  height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.callories-number {
  font-weight: bold;
  color: #3430ff;
}

.callories-unit {
  color: #a5a5a5;
}

.carb-number {
  font-weight: bold;

  color: #ff8730;
}
.carb-unit {
  color: #a5a5a5;
}

.fat-number {
  font-weight: bold;

  color: #ff303c;
}

.fat-unit, .protein-unit {
  color: #a5a5a5;
}

.protein-number {
  font-weight: bold;

  color: #9830ff;
}

.subMenu {
  cursor: pointer;
}
.subRecipeDisplayIcon {
  display: flex;
}
.subRecipeDisplayIcon .subRecipeIcons {
  margin-left: 4px;
}
.subRecipeDisplayIcon .subRecipeAllergenIcons {
  margin-left: 8px;
}
.ant-modal-title .title.font {
  color: #212121;
}
.ant-modal-header {
  padding: 16px 24px;
}
.ant-modal-body .imgtag {
  padding: 8px 10px;
  height: auto;
}
.block.block1 {
  margin-bottom: 4px;
}

.sustainable-label {
  // width: 125px;
  // height: 18px;
  // font-style: normal;
  // font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #a5a5a5;
  margin-top: 16px;
}

.sustainable-text {
  // width: 432px;
  // height: 20px;
  // font-style: normal;
  // font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-top: 8px;
  font-family: 'Sora-Regular';
}

.sutainable-block-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.sutainable-block-container .sutainable-block-content {
  width: 50%;
}
