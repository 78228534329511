.requisition-list-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child
  .tableTitle {
  justify-content: flex-start;
}

.requisition-list-wrapper
  .ant-table-tbody
  > tr
  > td:first-child {
    width: 35px !important;
  }

.wizardContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 700px;
  font-family: 'Sora-Regular';
  max-width: 560px;
  margin: auto;
}

.recipeWizardStepperContainer {
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  row-gap: 5px;
  width: 55%;
  margin: auto;
  height: 100%;
  position: relative;
}

// .alertMessage {
//   position: absolute;
//   z-index: 1;
//   width: 80%;
//   right: 33px;
// }

.wizardAlert .anticon svg {
  color: red;
}
