.combo-alertMessage {
  position: absolute;
  z-index: 1;
  width: 100%;
  right: 0;
}

.combo-wizard {
  max-width: 700px !important;
}
