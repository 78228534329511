@import '../../theme/includes.scss';

.login-form {
  display: flex;
  flex-direction: column;
  padding: 0 3rem;

  input,
  .ant-input-password {
    padding: 8px 10px;
    border-radius: 8px;
  }

  .login-btn {
    width: 100%;
    border-radius: 10px;
    padding: 5px;
  }
}

.sign-up-btn {
  width: 100%;
  border-radius: 10px;
  padding: 5px;
  background-color: #c0110c;
  span {
    color: white;
  }
}

.sign-up-btn:hover {
  background-color: #f93730;
  span {
    color: white;
  }
}

.email-btn {
  display: flex;
  border-radius: 5px;
  gap: 5px;
  padding: 5px 10px;
  background-color: #c0110c;
  border: none;
  color: white;
  // width: 110px;
  letter-spacing: 1px;
  align-items: center;
}

.text-center {
  text-align: center;
  margin-bottom: 10px;
}

.authContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;

  .ant-form label {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.or-seperator {
  margin-top: 10px;
  text-align: center;
  border-top: 1px solid #ccc;
  // margin-bottom: 10px;
}

.or-seperator i {
  padding: 0 10px;
  background: #f7f7f7;
  position: relative;
  top: -11px;
  z-index: 1;
}

.spacing {
  margin-top: 10px;
}

.error {
  color: red;
  margin-bottom: 10px;
  margin-top: 20px;
}

.textRight {
  text-align: end;
}

.leftSide {
  background-color: $pagecontentBlockColor;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-shadow: 0 0 10px 5px #ebebeb;
  border-radius: 20px;

  .ant-form-item {
    margin-bottom: 10px;
  }
  .sideLogoContainer {
    display: flex;
    justify-content: center;
    
    .sideLogo {
      width: 50%;
      margin: 2rem 0;
    }
  }
}

.sideLogo {
  // margin: 4rem;
}

.rightSide {
  background-color: $pagecontentBlockColor;
  display: flex;
  justify-content: center;
  height: -webkit-fill-available;
}

.successIcon {
  color: $success;
}

.failedIcon {
  color: $error;
}

.success {
  color: green !important;
  margin-top: 20px;
}

.successForEmail {
  color: green !important;
  margin-top: 10px;
}

.b {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.title-description-container {
  margin: 20px 70px 60px 90px;
}
.login-welcome-title {
  font-family: 'ClashDisplay-Medium';
  font-size: 36px;
  line-height: 54px;
  color: #000000;
  margin-bottom: 12px;
}

.login-right-side-description {
  font-family: 'Sora-Regular';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  text-align: justify;
}
.loginsideImage {
  max-width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.loginsideImage img {
  width: 80%;
}

.login_Footer {
  background: no-repeat;
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  font-weight: bold;
  color: #7a7a7a;
  // padding: 3rem 4rem 4rem 4rem;
}
@media screen and (min-width: 1681px) {
  .butStyling {
    display: flex;
    gap: 10px;
    background: none;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (min-width: 1591px) and (max-width: 1680px) {
  .butStyling {
    display: flex;
    gap: 0;
    background: none;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .butStyling > div {
    width: 32%;
  }
  .butStyling button {
    width: 100% !important;
  }
}
@media screen and (max-width: 1590px) {
  .butStyling {
    display: flex;
    gap: 10px;
    background: none;
    flex-wrap: wrap;
    justify-content: center;
  }
}
