.BulkUploadSupplyIngredient .recipeWizardStepperContainer.wizard {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ebebeb;
}
.BulkUploadSupplyIngredient .imageWrapper {
  width: 280px;
  background: #f3f4f6;
  border: none;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.BulkUploadSupplyIngredient .imageWrapper img {
  margin-bottom: 11px;
}
.BulkUploadSupplyIngredient .imageWrapper .deleteButton img {
  margin-bottom: 0;
}
.BulkUploadSupplyIngredient .imageWrapper h4 {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #212121;
}

.BulkUploadSupplyIngredient .imageWrapper p {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #a5a5a5;
}

.BulkUploadSupplyIngredient .imageUpload {
  width: 100%;
}
.BulkUploadSupplyIngredient .wizardSubContainer.checkBox {
  margin: 0;
  padding: 24px;
}
.BulkUploadSupplyIngredient .wizardSubContainer.checkBox .wizardLabel {
  margin-top: 0;
}
.BulkUploadSupplyIngredient .wizardSubContainer.checkBox .formgroup {
  border: none;
}
.BulkUploadSupplyIngredient .label-wrapper label {
  width: 100%;
}
.BulkUploadSupplyIngredient .label-wrapper {
  width: 100%;
}
.BulkUploadSupplyIngredient .dropDown-wrapper {
  padding-top: 16px;
}
.dropDown-wrapper label {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  padding-bottom: 8px;
  display: block;
}
.dropDown-wrapper label span {
  color: #f90101;
}
.dropDown-wrapper .ant-select-selector {
  height: 44px !important;
  align-items: center;
}
span.ant-select-selection-item {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #a5a5a5;
}
.BulkUploadSupplyIngredient .butonGroup {
  width: 100%;
  margin-top: 200px;
}

.fileDetailBlockRDSubRecipe {
  padding: 24px;
  background: #f5f3ef;
  height: 100%;
}
.fileDetailListSupplyIngredient {
  list-style: none;
}
.fileDetailListSupplyIngredient li {
  margin-bottom: 12px;
}
.list-contentRD-SubRecipe {
  display: flex;
  gap: 8px;
}
.fileDetailListSupplyIngredient p {
  margin: 0;
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #4b4b4b;
}
.fileDetailListSupplyIngredient p span {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #4b4b4b;
}
.listImgSupplyIngredient {
  margin: 12px 0;
}
.imgWrapper-SupplyIngredient {
  width: 48%;
}
.listImgSupplyIngredient .ant-row {
  justify-content: space-between;
}
.imgWrapper-SupplyIngredient img {
  width: 100%;
}

.ErrorContainerSupplyIngredient {
  background: #f3f3f3;
  margin: 10px;
  padding: 10px;
  color: #cb1d1d;

  p {
    margin-bottom: 5px;
  }
}
