@import '../../theme/includes';

html[lang='ar'] .rc-accordion-toggle {
  direction: rtl;
}

html[lang='ar'] .rc-accordion-title {
  margin-right: 10px;
  margin-left: 0;
}

html[lang='ar'] .rc-accordion-body {
  direction: rtl;
}

html[lang='ar'] .vertical-line:before {
  right: -15px;
  left: auto;
}

html[lang='ar'] .rc-accordion-subtitle {
  margin-right: 10px;
  margin-left: 0;
}

html[lang='ar'] .headcontainer {
  direction: rtl;
}

html[lang='ar'] .breadcrumbMainWrapper {
  direction: rtl;
}

html[lang='ar'] .breadcrumb {
  padding-right: 24px;
  padding-left: 0;
}

html[lang='ar'] .breadcrumb-button {
  transform: rotate(180deg);
}

html[lang='ar'] .ant-btn > .anticon + span,
html[lang='ar'] .ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}

html[lang='ar'] .listingTopFilter {
  direction: rtl;
}

html[lang='ar'] .ant-input-prefix {
  margin-right: 0px;
  margin-left: 4px;
}

html[lang='ar'] .ant-table table {
  direction: rtl;
}

html[lang='ar'] thead.ant-table-thead tr {
  direction: rtl;
}

html[lang='ar'] .footer {
  direction: rtl;
}

html[lang='ar'] .ant-pagination-next {
  transform: rotateY(180deg);
}

html[lang='ar'] .ant-pagination-prev {
  transform: rotateY(180deg);
}

html[lang='ar'] .ant-pagination-item {
  margin-left: 8px;
}
