@import '../../theme/includes';

.stepper {
  margin: 5%;
}
.stepper-button {
  margin-top: 26px;
}
.wastagecol1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wastagesubCol1 {
  margin-left: 15px;
}
.wastagecol1Text {
  font-weight: 700;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
}
.wastagesubTextCol1 {
  display: flex;
  align-items: center;
}
// .subCol1Icon {
//   padding: 5px;
//   border: 1px solid #d3d0cf;
//   border-radius: 50px;
//   margin-right: 8px;
// }
.calories {
  font-size: 14px;
  font-weight: 400;

  color: #a5a5a5;
  margin-left: 2px;
}
.font {
  font-family: 'Sora-SemiBold';
}

.wastagecol2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;
  width: max-content;
  cursor: pointer;
  height: 36px;
  background: #f5f3ef;
  border-radius: 100px;
}

/* .wastagesubCol2 {
  height: 20px;
  font-style: normal;
  font-family: 'Sora-Regular', 'Sora';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
} */

.wastagecol3 {
  height: 20px;
  left: 455px;
  top: 34px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
}
.wastagecol4 {
  width: 73px;
}

.dots {
  width: fit-content;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f4f7;
  border-radius: 50px;
  cursor: pointer;
}

.dotOption {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Sora-Regular';
}

body[dir='rtl'] .dotOption {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Sora-Regular';
  text-align: right;
}

.dropdownIcon {
  left: 11.09%;
  right: 14.74%;
  top: 9.03%;
  bottom: 8.46%;
}

.collapseContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 55px;
  height: 40px;
}
.collapseHead {
  font-family: 'Sora-SemiBold', 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #a5a5a5;
}

.detailButton {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  height: 40px;
  border: 1px solid #212121;
  border-radius: 100px;
  width: max-content;
  cursor: pointer;

  img {
    width: 20px;
  }
}

// .collapseDesc {
//   width: 360px;
//   height: 40px;
//   font-family: 'Sora-SemiBold';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 20px;
//   letter-spacing: -0.02em;
//   color: #212121;
// }

.line {
  height: 55px;
}

.tableTitle {
  display: flex;
  align-items: center;
  font-family: 'Sora-Regular';
  font-weight: 600;
}
.titleArrow {
  margin: 0px 5px;
  cursor: pointer;
}

.ant-card-body {
  padding: 16px;
}

.duplicateIcon {
  margin-left: -4px;
}

.stepperButon {
  margin: '0 8px';
}

.brandDiv {
  font-size: 24px;
  margin: 5% 0% 2% 2%;
}

.listingTopFilter {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
  margin-left: 10px;
}
// .ant-spin-nested-loading {
//   position: relative;
//   height: 680px;
//   overflow: auto;
// }

.wastageVerifyIcon {
  margin-right: 8px;
  width: 32px;
  height: 32px;
}
.wastagePage .card-block {
  padding-top: 16px;
}
