@import '../../../theme/includes';

.container {
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  row-gap: 5px;
  width: 100%;
  height: 100%;
  position: relative;
  border-right: 1px solid #ebebeb;
}

.filterScroll {
  height: 600px;
  overflow: auto;
}
.filterScroll .ant-collapse-content-box {
  padding: 8px 0 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black !important;
  border-color: black !important;
}

.buttonContainer {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 24px;
  bottom: 24px;
}

.resetBtn {
  background-color: white;
  border: 1px solid black;
  color: #101010;
  border-radius: 25px;
  width: 100%;
  font-size: 16px;
  padding: 12px;
}
.filterContainer .anticon svg {
  display: inline-block;
  color: #d3d0cf;
  width: 8px;
  height: 8px;
}

.filterTitle {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
}

.tagStyling {
  margin-right: 20px;
  background: gray;
  border-radius: 25px;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 2px;
}

.pill {
  display: flex;
  justify-content: space-between;
}

.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 14.5px;
  font-family: 'Sora-Regular';
  color: #000000;
  font-weight: 400;
}

.topHeading {
  width: 120px;
  height: 24px;
  font-family: 'Sora-SemiBold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #101010;
}

.linkText {
  width: 56px;
  height: 20px;

  font-family: 'Sora-SemiBold', 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #ff303c;
}

.linkText:hover {
  cursor: pointer;
  text-decoration: underline;
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.ant-checkbox-group-item {
  margin-right: 0 !important;
  margin-bottom: 8px;
}
.btnShow {
  width: 66px;
  height: 20px;

  font-family: 'Sora-SemiBold';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;

  color: #f9ab01;
}

.btnShow:hover {
  cursor: pointer;
  text-decoration: underline;
}

.btnHide {
  width: 66px;
  height: 20px;

  font-family: 'Sora-SemiBold';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;

  color: #f9ab01;
  display: none;
}

.mainHeading {
  display: flex;
  justify-content: space-between;
}

.filterContainer .ant-collapse {
  font-family: 'Sora-SemiBold', 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px;
  color: #101010;
}

.panelStyling {
  margin-bottom: 8px;
}
