.headcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  padding: 10px 10px;
  padding-left: 40px;
  background-color: #f5f3ef;
  flex-basis: 1;
}

.subHeadContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  flex-basis: 1;
}

.headBlock {
  display: flex;
  gap: 8px;
}

.headButton {
  display: flex;
  align-items: center;
}

.headButtonText {
  margin-right: 8px;
  cursor: pointer;
}

.secondSubHeadContainer .feedBackButton {
  text-align: center;
}

.secondSubHeadContainer .feedBackButton .ant-btn:hover {
  background-color: #ffedc7;
  border-radius: 10px;
}
.secondSubHeadContainer .feedBackButton .ant-btn {
  // height: 36px;
  height: 32px;
  // border-radius: 5px;
  // background-color: #febd2f;
  font-family: 'ClashDisplay-Medium';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  // border-radius: 32px;
  background-color: transparent;
  border: none;
}

.secondSubHeadContainer .feedBackButton .ant-btn:not([disabled]):hover {
  text-decoration: none;
  background-color: none;
}
.secondSubHeadContainer .feedBackButton .ant-btn:not([disabled]):focus {
  text-decoration: none;
  border: none;
}

.headTitle {
  font-size: 12px;
  line-height: 18px;
  color: #a5a5a5;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.headFont {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  width: max-content;
}

.profileImage {
  width: 32px;
  height: 32px;
}

.headImage {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subHeadImage {
  // width: 80%;
  // object-fit: contain;
  width: 100%;
  object-fit: fill;
  height: -webkit-fill-available;
  border-radius: 4px;
}

.profileSubText {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #a5a5a5;
}

.profileHead {
  display: flex;
  align-items: center;
  gap: 15px;
}

.pointer {
  cursor: pointer;
}
.customDropDown {
  width: 215px;
  position: absolute;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  background-color: #fff;
  top: 75px;
  left: 20px;
  z-index: 9;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.customLogoutDropdown {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 12px 40px 2px rgba(84, 84, 84, 0.12);
  border-radius: 10px;
  width: 220px;
  max-height: 180px;
  //height: 130px;
  top: 65px;
  right: 10px;
  z-index: 2;
}

.subcustomLogoutDropdown {
  background: #fff;
  border-radius: 10px;
  width: 204px;
  max-height: 174px;
  // height: 150px;
  margin: 8px;
  padding: 16px 12px;
}

.subcustomLogoutDropdownTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.brandBlock {
  margin-top: 14px;
  margin-bottom: 12px;
}

.brandBlockTitle {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #a5a5a5;
}

.branchBlockWidth {
  width: 135px;
  // display: none;
}

.branchBlockWidthHidden {
  width: 135px;
  display: none;
}

.brandBlockWidth {
  width: 200px;
  // display: none;
}

.brandBlockWidthHidden {
  width: 200px;
  display: none;
}

.companyBlockWidth {
  max-width: 40%;
  // display: none;
}

.companyBlockWidthHidden {
  max-width: 40%;
  display: none;
}

.logoutBlock {
  margin: 14px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #ab2626;
  cursor: pointer;
}
.brandBlockText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Sora-Regular';
  letter-spacing: -0.02em;
}
.profileIcon {
  width: 32px;
  height: 32px;
  object-fit: fill;
  border-radius: 4px;
}

.menu {
  border-bottom: 1px solid #ebebeb;
  margin: 6px;
  padding: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  z-index: 2;
}

.menuImage {
  width: 36px;
  height: 36px;
  margin-right: 7px;
}

.dotOptionMenu {
  align-items: center;
  padding: 12px 9px;
  // padding-left: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Sora-Regular';
  display: flex;
}

.DropdownHeader {
  height: 100px;
  left: 440px;
  top: 72px;
}

.secondSubHeadContainer {
  display: flex;
  gap: 16px;
}

.secondSubHeadContainer .ant-switch .ant-switch-handle {
  position: absolute;
  top: 6px;
  left: 3px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}

.secondSubHeadContainer .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 3px);
}

.secondSubHeadContainer .ant-switch .ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  // margin: 0 20px 0 0px;
  color: #fff;
  font-size: 14px;
  transition: margin 0.2s;
  font-family: 'ClashDisplay-Regular';
}
.secondSubHeadContainer .ant-switch-checked .ant-switch-inner {
  display: block;
  // margin: 0 7px 0 25px;
  margin: 0 20px 0 0px;
  color: #fff;
  font-size: 14px;
  transition: margin 0.2s;
  font-family: 'ClashDisplay-Regular';
}

.secondSubHeadContainer .ant-switch {
  background-color: #febd2f !important;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 60px;
  height: 30px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.goto-profile-submit-button {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 10px 10px 0px;
  background: #f5f3ef;
  padding: 10px 5px 10px 5px;
  border-radius: 10px;
  cursor: pointer;
}
.goto-profile-submit-button:hover {
  background: antiquewhite;
}

.billingBtn:hover {
  color: black;
}


li.ant-dropdown-menu-item:has(.dotOptionMenu) {
  padding: 0 !important;
}
// .feedBackButton .ant-btn {
//   height: 33px;

//   background-color: #febd2f;
//   font-family: 'ClashDisplay-Medium';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 16px;
//   line-height: 24px;
//   letter-spacing: -0.02em;
// }

// .feedBackButton .ant-btn:not([disabled]):hover {
//   text-decoration: none;
//   background-color: none;
// }
// .feedBackButton .ant-btn:not([disabled]):focus {
//   text-decoration: none;
//   border: none;
// }
// ipad
.linkgroup {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  font-family: 'Sora-Regular';
}
.linkgroup a {
  font-size: 9px;
}
.linkgroup a:hover {
  color: #f9ab01;
}
.linkgroup b {
  font-size: 17px;
}

.profileBtn {
  background-color: red;
  border: 1px solid red;

  span {
    color: white;
  }
}

.profileBtn:hover {
  border: 1px solid red;

  span {
    color: red;
  }
}

@media screen and (max-width: 1048px) {
  .subHeadContainer {
    width: 55%;
  }
}
//tab
@media screen and (max-width: 991px) {
  .headFont {
    font-size: 10px;
  }
  .headTitle {
    font-size: 8px;
  }
}
@media screen and (max-width: 768px) {
  .headFont {
    font-size: 8px;
  }
  .headTitle {
    font-size: 6px;
  }
  .customDropDown {
    width: 145px;
    left: 35px;
  }
  .dotOptionMenu {
    font-size: 8px;
  }
  // .menuImage {
  //   width: 24px;
  //   height: 24px;
  // }
  .ant-dropdown-menu {
    width: 118px;
  }
  .menu > img {
    width: 24px;
    height: 24px;
  }
}

.DropdownHeader.scrollable-dropdown .ant-dropdown-menu {
  max-height: 400px !important;
  overflow-y: auto !important;
}
