.uploadedimage {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}
.uploadRecipeImgWrapperStepOne {
  display: flex;
  align-items: center;
  gap: 20px;
}
.uploadRecipeImgWrapperStepOne img {
  border-radius: 8px;
}
.text {
  display: inline-grid;
  font-family: Sora;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.text :last-child {
  color: #17c313;
}
.deletebutton {
  display: inline;
}
.containerImage {
  padding: 3%;
  background-color: #fbfafb;
  padding: 24px;
  width: 100%;
  height: 400px;
  position: relative;
  border-right: 1px solid #ebebeb;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  margin-bottom: 20px;
}
