.SustainabilityStepTwo {
  width: 100%;
}

.SustainabilityStepTwo .ant-input {
  box-sizing: border-box;
  margin: 0;

  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;

  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;

  border: 1px solid #d9d9d9;

  transition: all 0.3s;
  padding: 12px 16px;

  height: 44px;
  background: #ffffff;

  border-radius: 10px;
}

.SustainabilityStepTwo .ant-input:focus-visible {
  outline: none;
  border: 1px solid #f9ab01;
}

.SubRecipe-stepTwo-input {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px;
}

.SubRecipeStepAddButton {
  width: 560px;
  height: 24px;
  font-family: 'ClashDisplay-Medium';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  cursor: pointer;
}

.SubRecipeTextArea .ant-input {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
  padding: 12px 16px;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 32px;
}

.SubRecipeImageUpload {
  overflow: hidden;
  height: 280px;
  width: 560px;
  text-align: center;
  background-color: #fbfaf9;
  border-radius: 10px;
  position: relative;
  border: 1px dashed #dddddd;
}

.SubRecipeImageWrapper {
  display: inline-block;
  position: relative;
}

.SubRecipeImageHeight {
  height: 280px;
  width: 100%;
}

.SubRecipeFormgroup {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  flex-wrap: wrap;
}
.SubRecipe-label-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.SubRecipeFormgroup label {
  background: #fbfaf9;
  border: 1px dashed #dddddd;
  border-radius: 5px;
  font-size: 16px;
  color: #000;
  width: 560px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 280px;
}
.SubRecipeFormgroup input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.SubRecipeWizardLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 24px 0px 8px 0px;
}

.SustainabilityStepTwo .ant-input:focus,
.ant-input-focused {
  border-color: #f9ab01;
  box-shadow: 0 0 0 0 rgb(255, 255, 255);
  border-right-width: 1px;
  outline: 0;
}

.wizardLabelAdvance {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}

.SustainabilityStepTwo-MainHead {
  margin-top: 24px;
  width: 100%;
  font-family: 'Sora-SemiBold';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
}

.SustainabilityStepTwo-Head {
  width: 100%;
  font-family: 'Sora-SemiBold';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
}

.SubRecipeWizard-subHead {
  margin: 24px;
  width: 100%;
  font-family: 'Sora-SemiBold';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
}

.SubRecipePracticeContainer {
  width: 100%;
  margin: 0px 0px 24px 0px;
}

.SubRecipePracticeContainerJustify {
  display: flex;
  gap: 16px;
  align-items: center;
}

.SubRecipeSubContainerPractice {
  width: 272px;
}

.SubRecipeSubContainerPractice
  > .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 11px;
  border-radius: 10px;
}

.SubRecipeSubContainerPractice > .ant-select-lg {
  font-size: 14px;
}

.SubRecipePracticeText .ant-input {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
  padding: 12px 16px;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 32px;
}

.SubRecipePracticeLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}
.allergens-content {
  width: 100%;
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.allergens-content li {
  width: 25%;
  margin-bottom: 18px;
  display: flex;
  gap: 10px;
  color: #212121;
  align-items: center;
}

.checkboxIngredient {
  width: 16px;
  height: 16px;
  border-radius: 3px;
}

.SubRecipeWizardSubContainer {
  width: 100%;
  margin: 0px 0px 24px 0px;
}

.ingredientLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}

.SubRecipeWizardSubContainer > .ant-input:placeholder-shown {
  text-overflow: ellipsis;
  border-radius: 10px;
  height: 44px;
}

.SubRecipeWizardadditonal {
  display: flex;
  align-items: center;
}
.SubRecipeWizardadditonal > div {
  width: 25%;
}
.SubRecipeUnitContainer {
  display: flex;
  align-items: center;
  border: 1px solid #d3d0cf;
  border-radius: 10px;
}

.SubRecipeUnitSubContainer {
  display: flex;
  margin: 12px 16px;
  align-items: center;
  justify-content: space-between;
}

.SubRecipeBorderContainer {
  border-right: 1px solid #d3d0cf;
  width: 25%;
}
.SubRecipeBorderContainer .borderContainer:last-child {
  border-right: none;
}

.borderRemove {
  width: 25%;
}
.SubRecipeUnitSubContainer .gramValue {
  width: 50%;
}
.stepThreeproteinBox {
  width: 25%;
}

.unitColor {
  color: #a5a5a5;
}

.proteinText {
  margin-left: 25px;
}

input[type='checkBox'] {
  accent-color: #212121;
}

.allergens {
  margin-bottom: 12px;
}

.loadIngredient {
  height: 100px;
  display: flex;
  align-items: center;
}

.addtionalAttributeLabel {
  font-size: 14px;
}
