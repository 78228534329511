.comingSoonDialogContainer .gifWidth {
  width: 400px;
}

.comingSoonDialogContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  margin: auto;
}

.comingSoonDialogContainer .messageText {
  font-family: 'Sora-Regular';
  font-size: 24px;
  margin-bottom: 200px;
}

.comingSoonDialogContainer .info {
  color: #1677ff;
}

.comingSoonDialogContainer .comingSoon {
  color: #febd2f !important;
}

/* .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: clamp(2rem, 2.8vw, 5rem);
  font-family: Raleway;
  font-weight: bold;
} */

.typewriter {
  width: 21.5ch;
  white-space: nowrap;
  overflow: hidden;
  border-right: 4px solid #212121;
  animation: cursor 1s step-start infinite, text 5s steps(18) alternate infinite;
}

.buttonTextSize span {
  font-size: 24px !important;
}

@keyframes cursor {
  0%,
  100% {
    border-color: #212121;
  }
}

@keyframes text {
  0% {
    width: 0;
  }
  100% {
    width: 65.5ch;
  }
}
