.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
}
.DialogWrapper .anticon svg {
  display: inline-block;
  color: #d3d0cf;
}

.InvoiceContainer {
  .ant-modal-body {
    padding: 24px !important;
  }
  .wizardLabel {
    margin-top: 0;
  }
  .invoiceBtnContainer {
    display: flex;
    justify-content: right;
    margin-top: 1rem;
  }
  .imageUpload {
    width: 100%;
  }
}
