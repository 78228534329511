.steps-heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: black;
  font-size: 20px;
  font-weight: 900;
  text-shadow: 1px 1px 1px #000;
}

.Modal .help {
  height: 110px;
}

.responseContainer {
  padding: 0px 30px 30px 30px;
}
