@import '../../theme/includes';

.gridViewItemGap {
  margin-left: 3px;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.percentagePrice {
  font-family: 'Sora-Regular';
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: #a5a5a5;
  // margin-left: 4px;
  font-weight: 600;
}

.rupeesTag {
  font-family: 'Sora-SemiBold' !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: right;
  color: #3430ff;
}

.listBox {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  background-color: white;
  z-index: 99;
  width: 160px;
  right: 0;
  top: 100%;
  border: 1px solid #d3d0cf;
  border-radius: 8px;
  margin-top: 5px;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.menubar-left-right-buttons {
  display: flex;
  align-items: center;
}

.menubar-back-button {
  // position: absolute;

  // top: 30px;
  // z-index: 1;
  // margin: 0px 10px;
  padding: 10px;
  margin-left: 10px;
  border-radius: 50px;
}

.menubar-back-button:hover {
  background-color: #febd2f;
}

.menubar-forward-button {
  // position: absolute;
  // display: flex;
  // align-items: center;
  // top: 30px;
  // z-index: 1;
  // margin: 0px 10px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 50px;
  right: 45px;
}

.menubar-forward-button:hover {
  background-color: #febd2f;
}

.listBoxText {
  font-family: 'ClashDisplay-Medium';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  padding: 12px;
}

.editButtonStyle {
  // padding-left: 20px;
  // padding-bottom: 20px;
  margin-top: 20px;
}

.lock-icon-style {
  padding: 10px;
  border: 2px solid #dedede;
  border-radius: 50px;
  font-size: 20px;
}

.menuBarMainWrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 3px;
}

.menuContainer {
  padding: 24px 0px 16px 24px;
  display: flex;
  // justify-content: space-between;
  gap: 6px;
  width: 100%;
  position: relative;
  max-width: 1570px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.menuContainer .menu {
  margin: 0;
  // padding: 0px 7px;
  // padding: 0px 10px;
  gap: 0px;
  min-width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  background: #efece6;
  border-radius: 100px;
}
.menuContainer .menuLight {
  // min-width: 140px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  // padding: 0px 7px;
  padding: 0px 20px;
  border: 1px solid #d3d0cf;
  background: white;
  border-radius: 100px;
  transition: all 0.3s;
  position: relative;
  justify-content: center;
}

.menuContainer .menuLightWithWidth {
  min-width: 120px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  // padding: 0px 7px;
  padding: 0px 20px;
  border: 1px solid #d3d0cf;
  background: white;
  border-radius: 100px;
  transition: all 0.3s;
  position: relative;
  justify-content: center;
}

.menuContainer .menuLight:last-child {
  justify-content: center;
}
.menuContainer .menuLight:hover {
  cursor: pointer;
  background: #efece6;
  margin-top: -6px;
}

.menuContainer .menuLightWithWidth:hover {
  cursor: pointer;
  background: #efece6;
  margin-top: -6px;
}

.menuContainerMargin {
  margin-left: 10px;
}

// .imageStye {
//   display: flex;
//   align-items: flex-start;
//   justify-content: flex-start;
//   // margin-top: 11px;
//   position: absolute;
//   left: 5px;
//   top: 50%;
//   transform: translateY(-50%);
// }

.menuDark {
  background: #efece6;
  // min-width: 140px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  padding: 0px 20px;
  border: 1px solid #d3d0cf;
  border-radius: 100px;
  transition: all 0.3s;
  justify-content: center;
  position: relative;
}

.menuDarkWithWidth {
  background: #efece6;
  min-width: 120px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  padding: 0px 20px;
  border: 1px solid #d3d0cf;
  border-radius: 100px;
  transition: all 0.3s;
  justify-content: center;
  position: relative;
}

.menuContainer .text {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  font-family: 'ClashDisplay-Medium';
  font-style: normal;
  font-weight: bold;
  // text-transform: capitalize;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.028em;
  color: #101010;
  text-align: center;
  white-space: nowrap;
}

@media screen and (max-width: 1440px) {
  .menuContainer {
    padding: 24px 0px 16px 0px;
    display: flex;
    gap: 6px;
    position: relative;
    max-width: 1172px;
    min-width: 1172px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .menuContainerWithoutDrag {
    max-width: 1170px !important;
  }
}

@media screen and (max-width: 1024px) {
  .menuContainer {
    padding: 24px 0px 16px 24px;
    display: flex;
    gap: 6px;
    position: relative;
    max-width: 710px;
    min-width: 710px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .menuContainerWithoutDrag {
    max-width: 750px !important;
  }
}

@media screen and (max-width: 1280px) {
  .menuContainer {
    padding: 24px 0px 16px 24px;
    display: flex;
    gap: 6px;
    position: relative;
    max-width: 710px;
    min-width: 710px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .menuContainerWithoutDrag {
    max-width: 750px !important;
  }
}



