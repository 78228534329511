@import '../../theme/includes';

.search-wrapper {
  display: flex;
  gap: 24px;
  justify-content: flex-end;
}
.search-box {
  max-width: 328px;
  width: 100%;
}
.search-wrapper .gridIconContainer {
  margin-left: 0;
}
.categoryPill {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 16px;
  gap: 10px;
  height: 26px;
  background: #f5f3ef;
  border-radius: 100px;
}

.pillText {
  font-family: 'Sora-Regular';
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #101010;
}

.title {
  font-family: 'Sora-SemiBold';
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #101010;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
}

.tooltipHead {
  font-family: 'Sora-SemiBold', 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-radius: 20px;
  letter-spacing: -0.02em;

  color: #ffffff;
}

.tootipText {
  font-family: 'Sora-SemiBold', 'Sora';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;

  color: #ffffff;
}

.textContainer {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #999999;
  margin-top: 7px;
  text-align: left;
}

.readMoreBtn {
  color: #febd2f;
  cursor: pointer;
}

.menuTitleContainer {
  display: flex;
  flex-direction: column;
}
.exportText {
  font-family: 'ClashDisplay-Medium';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
}
.loader-wrapper {
  height: 684px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stepper {
  margin: 5%;
}
.stepper-button {
  margin-top: 26px;
}

.subCol1 {
  margin-left: 15px;
}
// .col1Text {
//   font-weight: 700;

//   font-size: 16px;
//   line-height: 24px;
// }
.subTextCol1 {
  display: flex;
  align-items: center;
}
// .subCol1Icon {
//   padding: 5px;
//   border: 1px solid #d3d0cf;
//   border-radius: 50px;
//   margin-right: 8px;
// }
.calories {
  font-size: 14px;
  font-weight: 400;

  color: #a5a5a5;
  margin-left: 2px;
}
.font {
  font-family: 'Sora-SemiBold';
}

// .col2 {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   padding: 8px 16px;
//   gap: 10px;
//   width: fit-content;
//   height: 36px;
//   left: 321px;
//   top: 28px;
//   background: #f5f3ef;
//   border-radius: 80px;
// }
.subCol2 {
  width: 100%;
  height: 20px;
  font-family: 'Sora-Regular', 'Sora';
  font-size: 14px;
}

// .col3 {
//   min-width: 110px;
//   height: 20px;
//   left: 455px;
//   top: 34px;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 20px;
//   letter-spacing: -0.02em;
//   color: #212121;
// }

.cellSpacing {
  margin-bottom: 8px;
}

// .tableCol {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   padding: 0px;
//   gap: 4px;
// }

.col4 {
  width: 73px;
}

.dots {
  width: fit-content;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f4f7;
  border-radius: 50px;
  cursor: pointer;
}

.dotOption {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Sora-Regular';
}

.dropdownIcon {
  left: 11.09%;
  right: 14.74%;
  top: 9.03%;
  bottom: 8.46%;
}

.collapseContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px 55px;
  height: 40px;
}
.collapseHead {
  font-family: 'Sora-SemiBold', 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #a5a5a5;
}

// .collapseDesc {
//   width: 360px;
//   height: 40px;
//   font-family: 'Sora-SemiBold';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 20px;
//   letter-spacing: -0.02em;
//   color: #212121;
// }

.line {
  height: 55px;
}

/* .anticon svg {
  display: inline-block;
  color: #000;
}

.anticon svg {
  width: 12px;
  height: 12px;
} */

.tableTitle {
  display: flex;
  align-items: center;
  font-family: 'Sora-Regular';
  font-weight: 600;
}
.titleArrow {
  margin: 0px 5px;
  cursor: pointer;
}

.ant-card-body {
  padding: 16px;
}

.duplicateIcon {
  margin-left: -4px;
}

.stepperButon {
  margin: '0 8px';
}

.brandDiv {
  font-size: 24px;
  margin: 5% 0% 2% 2%;
}

.listingTopFilter {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
  margin-left: 10px;
}

.menu-donut-chart_container {
  display: flex;
  align-items: center;
}

.actual-price-color-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000;
  font-family: 'Sora-SemiBold';
}
// .ant-spin-nested-loading {
//   position: relative;
//   height: 680px;
//   overflow: auto;
// }
@media screen and (min-width: 1025px) {
  .search-wrapper .gridIconContainer {
    margin-right: 16px;
  }
}
@media screen and (max-width: 991px) {
  .search-wrapper {
    margin-top: 20px;
  }
}

.selling-price {
  color: orange;
}

.fire-icon-menu {
  margin-right: 0px !important;
}
