.ComboLabelContainer {
  display: flex;
  align-items: center;
}

// .combo-wizard-image-uploader .label-wrapper label {
//   width: 100 !important;
// }

.combo-wizard-step2-head {
  width: 81% !important;
}

.variantWidth {
  width: 300px;
}

.variantWidthfoodics {
  width: 300px;
}

.menuWidth {
  width: 310px;
}

.menuWidthfoodics {
  width: 285px;
}

.qtyWidth {
  width: 90px;
}

.qtyWidthfoodics {
  width: 90px;
}

.netPriceWidthfoodics {
  width: 75px;
}

.salePriceWidth {
  width: 90px;
}

.netPriceWidth {
  width: 120px;
}

.ComboLabelContainer
  .variantWidth
  // .ant-select:not(.ant-select-customize-input)
  // .ant-select-selector 
  .ant-select-lg .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  padding: 3px 11px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.35);
}

.ComboLabelContainer
  .menuWidth
  // .ant-select:not(.ant-select-customize-input)
  // .ant-select-selector 
  .ant-select-lg .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  padding: 3px 11px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.35);
}

// .menuWidth > .ant-select-lg > .ant-select-selector {
//   position: relative;
//   background-color: #fff;
//   border: none;
//   border-right: 1px solid #d3d0cf;
//   border-radius: 0px;
// }
// .variantWidth > .ant-select-lg > .ant-select-selector {
//   position: relative;
//   background-color: #fff;
//   border: none;
//   border-right: 1px solid #d3d0cf;
//   border-radius: 0px;
// }

.menuWidth
  > .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 44px;
}

.variantWidth
  > .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 44px;
}

// .variantWidth
//   > .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
//   .ant-select-selector {
//   padding: 3px 11px;
// }

.menuWidth
  > .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 3px 11px;
}

.usdLabel {
  padding: 9px 10px;
  width: 56px;
  height: 44px;
  border: none;
  border-right: 1px solid #d3d0cf;
  border-radius: 0px;
  background: #e7e9ee;
}

.qtyWidth .ant-input {
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.78;
  background-color: #fff;
  background-image: none;
  border: none;
  border-right: 1px solid #d3d0cf;
  border-radius: 0px;
  transition: all 0.3s;
  height: 44px;
}

.usdValueDiv {
  width: 66px;
  background-color: #f3f4f6;
  line-height: 44px;
  padding: 0px 5px;
}

.inputFieldContainer {
  border: 1px solid #d3d0cf;
  border-radius: 10px;
  overflow: hidden;
}

.inputComboMenuContainer {
  position: relative;
  margin-bottom: 8px;
}

.comboMenuDelete {
  position: absolute;
  right: -24px;
  top: 12px;
  cursor: pointer;
}

.menuWidth > .ant-select-lg {
  font-size: 14px;
}

.variantWidth > .ant-select-lg {
  font-size: 14px;
}

.variantWidth
  .ant-select-lg
  .ant-select-selector
  .ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.comboPriceFieldLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}

.bottomFieldComboMargin {
  margin-bottom: 24px;
}

.ComboLabelContainer .qtyWidth .ant-input {
  border-radius: 0;
}

.foodicscombocontainer {
  margin-bottom: 20px;
}

.groupContainer {
  width: 700px;
  border: 1px solid #d9d9d9; 
  border-radius: 8px; 
  padding: 0px 10px 10px 10px; 
  margin-top: 15px;
}

.newItemContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.newItemContainer .ant-input {
  margin-right: 10px;
}

.recipe-width {
  width: 250px !important;
}

.foodics-delete {
  right: -35px;
}
