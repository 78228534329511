.dropdownLabels {
  width: 80px;
  line-height: 28px;
}
.comboPage .gridIconContainer {
  margin-right: 0 !important;
}

.textContainer {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #999999;
  margin-top: 7px;
  text-align: left;
}

.listView-first-column-margin {
  margin-left: 20px;
}
