.SupplierDetailContainer .title {
  font-family: 'Sora-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-bottom: 16px;
}
.SupplierDetailContainer .content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.SupplierDetailContainer .content-text {
  width: 50%;
  // margin-top: 20px;
  margin-bottom: 24px;
}

.spacingTag {
  margin-top: 24px;
}

.mod-category {
  margin-top: 26px;
}

.modifier-category {
  margin-right: 5px;
  color: #101010 !important;
}

.modifier-max {
  color: #101010 !important;
}

.modifier-sub {
  text-transform: lowercase;
}

.mod-name {
  text-transform: uppercase;
}

.mod-ing {
  text-transform: uppercase;
  color: #a5a5a5 !important;
}

.stock-taking-export {
  background-color: #febd2f;
  width: fit-content;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  gap: 10px;
  display: flex;
}

.stock-taking-heading {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.SupplierDetailContainer .content-text p:first-child {
  font-family: 'Sora-SemiBold';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #a5a5a5;
  margin-bottom: 20px;
}
.SupplierDetailContainer .content-text p:last-child {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #101010;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0;
  margin-top: 10px;
}
li.content-list {
  width: 100%;
  padding: 11px 16px;
}
li.content-list-headings {
  width: 100%;
  padding: 11px 16px;
}
li.content-list-headings {
  background: #febd2f;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-family: 'Sora-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #212121;
  margin-bottom: 0;
  text-align: center;
}
li.content-list:not(:first-child) {
  border-bottom: 1px solid #d3d0cf;
}
li.content-list p {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #212121;
  margin-bottom: 0;
  text-align: center;
}

.marginDown {
  margin-bottom: 10px;
}

.stylingAcceptButton {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.supply-purchaseOrder-container {
  width: 620px;
}

.DetailDialog .ant-modal .ant-modal-header {
  width: 100%;
}

.DetailDialog .ant-modal .ant-modal-body {
  width: 100% !important;
}

.stock-count-submit-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.stock-count-modal-container .ant-modal-content {
  width: 640px;
}

.stock-count-modal-container .ant-modal-content .ant-modal-body {
  width: 640px !important;
}

.SupplierDetailContainer li ul li .ant-row .font-input-field p .ant-input {
  font-family: 'Sora-Regular';
  height: 22px;
  width: 74px;
  font-size: 12px;
}

.SupplierDetailContainer
  li
  ul
  li
  .ant-row
  .supplier-table-TickCrossIcons
  input {
  width: 70px;
  border-radius: 8px;
}

.SupplierDetailContainer
  li
  ul
  li
  .ant-row
  .supplier-table-TickCrossIcons
  input {
  width: 70px;
  border-radius: 8px;
}

.status-container-margin-override {
  margin: 0 !important;
}

.suply-modal-status-container {
  font-family: 'Sora-Regular' !important;
  font-size: 14px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  gap: 12px;
  border-radius: 100px;
  width: fit-content;
}

.edit-po {
  gap: 8px;
}
.error-stock {
  color: red !important;
  font-size: smaller;
}

.saveBtn {
margin-right: 5px;
}

.mod-dialog {
  font-family: "Sora-Regular";
  color: #a5a5a5 !important;
  margin-bottom: 5px !important;
}

.mod-text {
margin-bottom: 5px !important;
}

.ing-table{
  margin-top: 20px;
}

.category-text {
  font-family: "Sora-Regular" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  text-transform: uppercase;
  color: #101010 !important;
  display: flex;
  align-items: center;
  gap: 5px;
}
