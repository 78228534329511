.calorie-container-each-ingredient {
  margin-left: 15px !important;
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.unit-container-each-ingredient {
  margin-left: 50px !important;
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.deleteButton {
  margin-right: 20px;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent;
}

.add-labels-with-ingredient-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.add-labels-with-ingredient-container .PO-labels-sub-container {
  margin-right: -19rem;
  gap: 25px;
}

.add-labels-with-ingredient-container .labels-sub-container {
  margin-right: -12rem;
  gap: 25px;
}

.purchaseOrder-gramContainer {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-bottom: 8px;
  position: relative;
}
.recipeBuilderWizardStepTwo .wizardSubContainer .purchaseOrder-gramContainer {
  justify-content: flex-start;
}
.recipeBuilderWizardStepTwo
  .wizardSubContainer
  .purchaseOrder-gramContainer
  .deleteButon {
  margin-left: 15px;
  position: static;
}

.purchase-order-ingredient-checkbox {
  position: absolute;
  right: -16rem;
}

.PO-update-cost-checkbox-label {
  position: absolute;
  left: -130px;
}

.calorie-each-value-container .ant-input-number-handler-wrap {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}

.calorie-each-value-container .ant-input-number-input-wrap input {
  width: 100%;
  height: 20px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}

.pocontainer {
  padding: 20px 20px 20px 60px;
}

.po-btn {
  padding-top: 20px !important;
  width: 100% !important;
}

.col-qty {
  display: inline-block;
}

.po-quantity {
  border-radius: 10px;
  width: 100px !important;
}
