.InventoryTransferApprovalContainer .ant-modal-content .ant-modal-body {
  min-height: 140px;
  width: 630px !important;
}

.aprroval-inventory-modal-container {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  font-family: 'Sora-Regular';
  margin-top: 10px;
}

.aprroval-inventory-modal-container-label {
  display: flex;
  align-items: center;
  gap: 26px;
  font-family: 'Sora-Regular';
  margin-top: 10px;
}

.aprroval-inventory-modal-container input {
  // width: 33.3%;
  // border: none;
  width: 60.3%;
  border: none;
  padding-left: 0;
}

.aprroval-inventory-modal-container input:focus {
  border-color: none;
  box-shadow: none;
  border-right-width: none;
  outline: 0;
}
.aprroval-inventory-modal-container div {
  // margin: 8px;
  width: 33.3%;
  border-right: 1px solid #d9d9d9;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aprroval-inventory-modal-container .subRecipeErrorMessages {
  font-family: 'Sora-Regular';
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #ff303c;
  width: 100%;
  margin: 5px;
  position: absolute;
  left: 283px;
  bottom: -20px;
}

.aprroval-inventory-modal-container .borderRed {
  border: 1px solid #ff303c !important;
  border-radius: 0px 8px 8px 0px;
}
