.success {
  color: green;
  margin-bottom: 10px;
  margin-top: 50px;
}

.authContainerForConfirmPage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftSideForConfirmPage {
  display: flex;
  flex-direction: column;
  padding: 0;
  box-shadow: 0 0 10px 5px #ebebeb;
  border-radius: 20px;
  background-color: #fff;

  .sideLogoContainer {
    display: flex;
    justify-content: center;
    
    .sideLogo {
      width: 50%;
      margin: 2rem 0;
    }
  }
}

.authContainerForConfirmPage .ant-form label {
  font-size: 18px;
}

.authContainerForConfirmPage .alignment {
  display: flex;
  margin-bottom: 112px;
}
