@import '../theme/includes.scss';

:root {
    --page-header-width: 220px;
    $borderRadius: 4px;
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  ul {
    list-style: none;
  }
  
  a,
  button {
    color: inherit;
  }
  
  a {
    text-decoration: none;
  }
  
  button {
    background: none;
    cursor: pointer;
  }
