.wizardSubContainer {
  width: 560px;
}

.wizardSubContainer .ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 280px;
}

.wizardSubContainer
  > .ant-select-single
  .ant-select-selector
  .ant-select-selection-search-input {
  width: 560px;
  border-radius: 10px;
  padding: 12px 16px;
  font-size: 14px;
}

.checkBox {
  margin: 24px;
}

.checkBox > .ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0px 26px 0px 0px;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}

.wizardSubContainer > .ant-input-lg {
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 10px;
  height: 44px;
}

.wizardSubContainer > .ant-select-lg {
  font-size: 14px;
}

// .sterik {
//   color: #f90101;
// }

.wizardLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 24px 0px 8px 0px;
}

.wizardSubContainer
  > .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 560px;
  border-radius: 10px;
}

.wizardSubContainer .ant-upload.ant-upload-drag .ant-upload {
  padding: 0px 0px;
}

.ant-upload-text {
  font-family: 'Sora-Regular' !important;
  font-size: 14px !important;
  line-height: 20px !important;
  /* identical to box height, or 143% */
  letter-spacing: -0.02em;
  /* Text/Primary */
  color: #212121 !important;
}

.ant-upload-hint {
  font-family: 'Sora-Regular' !important;
  font-size: 12px !important;
  line-height: 18px !important;
  /* identical to box height, or 150% */
  letter-spacing: -0.02em;

  color: #a5a5a5 !important;
}

.label-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  width: 100%;
}

.formgroup {
  display: flex;
  justify-content: space-between;
  background: #fbfaf9;
  border: 1px dashed #d3d0cf;
  border-radius: 10px;
  flex-wrap: wrap;
}

.formgroup label {
  background: #fbfaf9;
  border: 1px dashed #dddddd;
  border-radius: 5px;
  font-size: 16px;
  color: #000;
  width: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 280px;
}
.formgroup input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.label-wrapper p {
  font-family: 'Brown';
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  color: #999999;
}

.imageUpload {
  overflow: hidden;
  height: 280px;
  width: 560px;
  text-align: center;
  background-color: #fbfaf9;
  border-radius: 10px;
  position: relative;
  border: 1px dashed #dddddd;
}

.imageHeight {
  height: 280px;
  width: 100%;
}

.imageWrapper {
  display: inline-block;
  position: relative;
}

.deleteButton {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.butonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px;
  gap: 8px;
  width: 560px;
}

.text-Area {
  border-radius: 10px;
}

// .wizard-button {
//   display: flex;
//   padding: 12px 16px;
//   gap: 12px;
//   width: 100px;
//   height: 48px;
//   border-radius: 100px;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
// }

.formgroupStepOne {
  border: none !important;
}
