@import '../../theme/includes';

// .exportButton {
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   padding: 12px 16px;
//   gap: 10px;
//   height: 40px;
//   border: 1px solid #212121;
//   border-radius: 100px;
// }

// .exportButton:hover {
//   cursor: pointer;
// }
.requisitions-search-wrapper {
  display: flex;
  gap: 24px;
  justify-content: flex-end;
}
.requisitions-search-wrapper .search-box {
  max-width: 250px;
  width: 100%;
  height: 48px;
  border-radius: 100px;
  align-items: center;
}
.requisitions-search-wrapper
  .search-box
  span.ant-input-affix-wrapper.b2.search-box.undefined {
  padding: 12px 8px 12px 19px;
}
.requisitions-search-wrapper .search-box .ant-input-prefix {
  margin-right: 13px;
}
.requisitions-search-wrapper .gridIconContainer {
  height: 48px;
}
// .variationBtn {
//   margin-left: 40px;
// }

.requisitions-search-wrapper .gridIconContainer {
  margin-left: 0;
}
// .categoryPill {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   padding: 4px 16px;
//   gap: 10px;
//   height: 26px;
//   background: #f5f3ef;
//   border-radius: 100px;
// }

// .pillText {
//   font-family: 'Sora-Regular';
//   font-size: 12px;
//   line-height: 18px;
//   letter-spacing: -0.02em;
//   color: #101010;
// }

// .title {
//   font-family: 'Sora-SemiBold';
//   font-size: 16px;
//   letter-spacing: -0.02em;
//   color: #101010;
// }

// .titleContainer {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   padding: 0px;
//   gap: 12px;
// }

// .tooltipHead {
//   font-family: 'Sora-SemiBold', 'Sora';
//   font-style: normal;
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 24px;
//   border-radius: 20px;
//   letter-spacing: -0.02em;

//   color: #ffffff;
// }

// .tootipText {
//   font-family: 'Sora-SemiBold', 'Sora';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 20px;
//   /* identical to box height, or 143% */

//   letter-spacing: -0.02em;

//   color: #ffffff;
// }

// .textContainer {
//   font-family: 'Sora-Regular';
//   font-weight: 400;
//   font-size: 14px;
//   letter-spacing: -0.02em;
//   color: #999999;
//   margin-top: 7px;
// }

// .readMoreBtn {
//   color: #febd2f;
// }

// .menuTitleContainer {
//   display: flex;
//   flex-direction: column;
// }
// .exportText {
//   font-family: 'ClashDisplay-Medium';
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 20px;
//   letter-spacing: -0.02em;
//   color: #212121;
// }
.requisitions-loader-wrapper {
  height: 684px;
  display: flex;
  align-items: center;
  justify-content: center;
}
// .stepper {
//   margin: 5%;
// }
// .stepper-button {
//   margin-top: 26px;
// }

// .subCol1 {
//   margin-left: 15px;
// }
// .col1Text {
//   font-weight: 700;

//   font-size: 16px;
//   line-height: 24px;
// }
// .subTextCol1 {
//   display: flex;
//   align-items: center;
// }
// .subCol1Icon {
//   padding: 5px;
//   border: 1px solid #d3d0cf;
//   border-radius: 50px;
//   margin-right: 8px;
// }
// .calories {
//   font-size: 14px;
//   font-weight: 400;

//   color: #d3d0cf;
//   margin-left: 2px;
// }
.requisitions-font {
  font-family: 'Sora-SemiBold';
}

// .col2 {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   padding: 8px 16px;
//   gap: 10px;
//   width: fit-content;
//   height: 36px;
//   left: 321px;
//   top: 28px;
//   background: #f5f3ef;
//   border-radius: 80px;
// }
// .subCol2 {
//   width: 100%;
//   height: 20px;
//   font-family: 'Sora-Regular', 'Sora';
//   font-size: 14px;
// }

.requisitions-column {
  min-width: 110px;
  height: 20px;
  left: 455px;
  top: 34px;
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
}

.requisitions-cell-spacing {
  font-family: 'Sora-Regular' !important;
  margin-bottom: 8px;
}

.requisitions-table-col {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
}

.requisitions-col4 {
  width: 120px;
}

.requisitions-dots {
  width: fit-content;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f4f7;
  border-radius: 50px;
  cursor: pointer;
}

.createdAtAlignment {
  margin-bottom: 15px;
}

.requisitions-dot-option {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Sora-Regular';
}

// .dropdownIcon {
//   left: 11.09%;
//   right: 14.74%;
//   top: 9.03%;
//   bottom: 8.46%;
// }

// .collapseContainer {
//   display: flex;
//   justify-content: space-between;
//   margin: 0px 55px;
//   height: 40px;
// }
// .collapseHead {
//   font-family: 'Sora-SemiBold', 'Sora';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 12px;
//   line-height: 18px;
//   letter-spacing: 2px;
//   text-transform: uppercase;
//   color: #a5a5a5;
// }

// .collapseDesc {
//   width: 360px;
//   height: 40px;
//   font-family: 'Sora-SemiBold';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 20px;
//   letter-spacing: -0.02em;
//   color: #212121;
// }

// .line {
//   height: 55px;
// }

.requisitions-table-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Sora-Regular';
  font-weight: 600;
}
.requisitions-title-arrow {
  margin: 0px 5px;
  cursor: pointer;
}

.requisitions-listingTopFilter {
  padding: 12px 24px;
  border-bottom: 1px solid #ebebeb;
  margin-left: 0;
  align-items: center;
}
// .ant-spin-nested-loading {
//   position: relative;
//   height: 680px;
//   overflow: auto;
// }

.requisition-iconDropdown {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
}

.requisition-iconDropdown svg {
  width: 18px;
  height: auto;
}

.requisition-table-serial-no {
  width: 100%;
  justify-content: center;
}

.requisitions-listingTopFilter .ant-col div .list-count .ant-row {
  display: flex;
  flex-flow: inherit;
  min-width: 240px;
}
@media screen and (min-width: 1025px) {
  .requisitions-search-wrapper .gridIconContainer {
    margin-right: 0;
  }
}
@media screen and (max-width: 991px) {
  .requisitions-search-wrapper {
    margin-top: 20px;
  }
}
