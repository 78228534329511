.minimumheight {
  min-height: 350px;
}

.BranchTextArea {
  height: auto !important;
}

.wizardSubContainer > div > .ant-input-lg {
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 10px;
}
