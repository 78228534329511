.hide {
  display: none;
}
.deleteIngredientForRecipe {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 12px;
}

.hideIngredient {
  position: absolute;
  right: -25px;
  top: 12px;
  display: none;
}

.orderTypeTextStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.orderTypeTextContainer {
  font-size: 12px;
  height: 44px;
  overflow-y: scroll;
  margin-left: 5px;
}

.brandIcons {
  margin: 5px;
  margin-right: 10px;
}

.withPlaceholder {
  border: 1px solid #cdcdcd;
  background: #f7f7f7;
  border-radius: 2px;
}

.subrecipe-calorie-container {
  margin-left: 15px;
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.subrecipe-unit-container {
  margin-left: 15px !important;
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.subrecipe-calorie-value {
  background: #f5f3ef;
  padding: 8px;
  border-radius: 10px;
  width: 70px;
  overflow: hidden;
}

.subrecipe-allergens-icons {
  display: flex;
  gap: 10px;
}

.recipeIngredient-calorie-value {
  position: absolute;
  right: -80px;
}

.recipeIngredient-graph {
  position: absolute;
  right: -115px;
}
.modifierIngredient .subDetails2 div {
  padding-left: 12px;
}
.recipe-cost {
  width: 160px !important;
}
.recipe-ingredient {
  margin-right: 20px;
}

.modifierdetails {
  width: 100px !important;
}
