.facebook_btn {
  display: flex;
  border-radius: 5px;
  gap: 5px;
  padding: 5px 10px;
  background-color: #3b5798;
  border: none;
  color: white;
  width: 150px;
  letter-spacing: 1px;
  align-items: center;
}
