.Modal {
  margin: 0px 0px 20px 0px;
}
.Modal .case {
  width: 432px;
  // height: 150px;
}

.CategoryModalwizardLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}

.modifierModalIngredientsLabel {
  width: 180px;
}
.wizardIngredientsLabel.modifierWizardIngredientsLabel {
  margin-top: 0;
}
.modifierWizardIngredientsLabel .modifierModalIngredientsLabel {
  width: 225px;
}
.modifierWizardIngredientsLabel .label:nth-child(2) {
  width: 98px;
  margin: 0;
}
.modifierWizardIngredientsLabel .label:nth-child(3) {
  width: 105px;
  margin: 0;
}
.modifierWizardIngredientsLabel .label:nth-child(4) {
  width: 85px;
  margin: 0;
}
.modifierWizardIngredientsLabel .label:nth-child(5) {
  width: 70px;
  margin: 0;
}
.modifierWizardIngredientsLabel .label:nth-child(6) {
  width: 70px;
  margin: 0;
}
.modifierWizardIngredientsLabel .label:nth-child(7) {
  width: 100px;
  margin: 0;
}
.modifierWizardIngredientsLabel .label {
  text-align: left !important;
}
.ant-modal-body:has(.modifierModal) {
  width: 700px !important;
}
.ant-modal-body::-webkit-scrollbar {
  display: none;
}
.ant-modal-body:has(.modifierModal),
.ant-modal-content:has(.modifierModal) {
  overflow: unset;
}

.add-mod-btn {
 margin-top: 30px;
}
