.minimumheight {
  min-height: 350px;
}

.modifier-alertMessage {
  position: absolute;
  z-index: 1;
  width: 100%;
  right: 0;
}

.modifier-subcontainer-margin {
  margin-top: 80px;
}

.wizardCategoryLabelContainer {
  margin-top: 1rem;
  width: 100%;
  display: flex;

  .categoryNameLabel {
    width: 55%;
  }

  .minValueLabel, .maxValueLabel {
    width: 40%;
  }
}

.inputFieldContainer {
  margin-top: 0.5rem;
  display:flex;
  border: 1px solid #d3d0cf;
  border-radius: 10px;
  overflow: hidden;

  input {
    border:none;
    padding: 12px 16px;
    font-size: 14px;
    border-radius: 10px;
  }

  .categoryNameInput {
    border-right: 1px solid #d3d0cf;
    width: 50%;
  }

  .minValueInput {
    border-right: 1px solid #d3d0cf;
  }
}

.modifier-error {
  margin-top: 55px;
  margin-bottom: 10px;
}
