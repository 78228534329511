.uploadImagesWizardForRB {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 700px;
  font-family: 'Sora-Regular';
  max-width: 560px;
  margin: auto;
}
.uploadImagesWizardForRB
  .uploadimageblock
  .recipeWizardStepperContainer.wizard {
  width: 48%;
}
.uploadImagesWizardForRB .butonGroup {
  margin-top: 15px;
}
