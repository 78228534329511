@import '../../../../theme/includes';

.form-group {
  max-width: 352px;
  position: relative;
}
.form-group label {
  font-family: 'Sora-Regular';
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #212121;
  padding-bottom: 8px;
  display: block;
}

.loaderStyle {
  margin-left: 50px;
  margin-top: 50px;
}
.form-group label span {
  color: #f90101;
}
.form-group input {
  width: 352px;
  padding: 12px 16px;
  gap: 4px;
  background: #ffffff;
  border: 1px solid #d3d0cf;
  border-radius: 10px 0px 0px 10px;
  height: 44px;
  border-right: none;
  font-size: 14px;
}
.form-group input:focus-visible {
  border: 1px solid #febd2f;
  outline: none;
}
.main-list {
  position: relative;
}
ul.dropdown-list {
  // position: absolute;
  left: 0%;
  right: 0%;
  top: 100;
  background: #ffffff;
  border: 1px solid #d3d0cf;
  box-shadow: 0px 12px 40px 2px rgba(84, 84, 84, 0.12);
  border-radius: 10px;
  list-style: none;
  padding-left: 0;
  margin-top: 5px;
  margin-bottom: 0;
  z-index: 99999;
  cursor: text;
}
ul.dropdown-list > li:first-child p {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin: 0;
  cursor: pointer;
}
ul.dropdown-list li h3 {
  font-family: 'Sora-SemiBold', 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-bottom: 0;
}
ul.subMenu {
  list-style: none;
  padding-left: 0;
  max-height: 354px;
  min-height: 345px;
  overflow: auto;
}
ul.subMenu li {
  padding: 11px 45px 11px 11px;
}

.placeholder {
  color: #a5a5a5;
  margin-bottom: 0;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
}

ul.subMenu li p {
  margin-bottom: 0;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
}
.subMenu > li {
  position: relative;
}

ul.searchMenu {
  list-style: none;
  padding-left: 0;
  height: 200px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
ul.searchMenu li {
  padding: 11px 17px;
}

ul.searchMenu li p {
  margin-bottom: 0;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
}
.searchMenu > li {
  position: relative;
}

.list-btn {
  position: absolute;
  right: 23px;
  top: 40px;
  height: 288px;
  overflow-y: scroll;
  z-index: 999;
  text-align: center;
  padding: 10px;
  cursor: text;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.list-btn p {
  margin-bottom: 0;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #212121;
  width: 20px;
  cursor: text;
}
.list-btn .active {
  background: #f5f3ef;
  border-radius: 100%;
}
li.create-category {
  position: relative;
  height: 42px;
}

li.create-category button {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: none;
  background: #000;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}
.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  background-color: #212121;
}

.IconStyling {
  cursor: text;
}

.buttonStyling {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  background-color: #212121;
  border: none;
}

.optionIcon {
  padding-left: 10px;
}

.dropdown-verified-icon {
  width: 24px;
  aspect-ratio: 1;
  height: auto;
}

.modeTwo {
  justify-content: space-between;
}

.mainWrapper {
  margin-top: 20px;
}

.selectStyle {
  width: 500px;
}

.dividerStyle {
  margin: 4px 0;
}

.subMenu > .ant-spin {
  color: #febd2f;
  width: 100%;
  display: flex;
  justify-content: center;
}
