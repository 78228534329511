.userListWizardContainer {
  max-width: 704px;
  margin: auto;
}
.userListWizard-block .recipeWizardStepperContainer.wizard {
  max-width: 585px;
  width: 100%;
  padding: 24px 0;
}

.userListWizardContainer .butonGroup {
  margin-top: 15px !important;
}

@media screen and (max-width: 991px) {
  .userListWizardContainer .butonGroup {
    width: 100%;
  }
}
