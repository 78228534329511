.ant-table-thead .ant-table-cell {
  color: #a5a5a5;
  background-color: #ebebeb;
}

.ExpandedTextAlignment {
  text-align: justify !important;
}

.expand-description-container {
  margin: 20px;
}

.expand-description-container .expand-description-title {
  text-align: left;
  font-family: 'Sora-SemiBold';
}

.expand-description-container .expand-description-text {
  font-family: 'Sora-Regular';
  font-size: 14px;
  text-align: justify;
  padding: 10px;
}
