.Modal {
  margin: 0px 0px 20px 0px;
}
.Modal .case {
  width: 432px;
  height: 150px;
}

.variationModalwizardLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}

.variation-disable-button {
  background: #ffedc7 !important;
  color: #888888 !important;
}

.recipe-variation {
 padding-bottom: 25px;
}
