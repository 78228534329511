.wizardContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 700px;
  font-family: 'Sora-Regular';
  max-width: 560px;
  margin: auto;
}

.continue {
  background: #febd2f;
  border: none;
}

span.category > span {
  background: #f3f4f6;
  width: 100%;
  border: 1px solid #d9d9d9;
  display: block;
  height: 50px;
  line-height: 44px;
  border-radius: 10px;
  padding-left: 10px;
}

// .alertMessage {
//   position: absolute;
//   z-index: 1;
//   width: 80%;
//   right: 33px;
// }

.wizardAlert .anticon svg {
  color: red;
}
