.supplyIngredientDialog {
  margin: 0px 0px 20px 0px;
}

.supplyIngredientDialogSubContainer {
  width: 560px;
  margin: 0px 0px 24px 0px;
}

.ant-modal-body::-webkit-scrollbar {
  display: none;
}
.ant-modal-header {
  width: 100%;
  border-radius: 0px;
}

// .ant-modal-content {
//   display: inline-block;
//   padding-bottom: 0;
//   text-align: left;
//   vertical-align: middle;
// }
.supplyIngredientDialogLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}

.supplyIngredientDialogSubContainer > .ant-input:placeholder-shown {
  text-overflow: ellipsis;
  border-radius: 10px;
  height: 44px;
}

.supplyIngredientDialogSpanWidth {
  width: 100%;
}

.supplyIngredientDialogSpanWidth
  > .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 11px;
  border-radius: 10px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.35);
}

.supplyIngredientDialogSpanWidth > .ant-select-lg {
  font-size: 14px;
}

.UOMAllergen {
  display: flex;
  gap: 16px;
  align-items: center;
}

.additonal {
  display: flex;
  align-items: center;
}
.additonal > div {
  width: 25%;
}
.unitContainer {
  display: flex;
  align-items: center;
  border: 1px solid #d3d0cf;
  border-radius: 10px;
}

.unitContainerDisable {
  background: #f5f5f5;
}

.unitSubContainer {
  display: flex;
  margin: 12px 16px;
  align-items: center;
  justify-content: space-between;
}

.borderContainer {
  border-right: 1px solid #d3d0cf;
  width: 25%;
}
.unitContainer .borderContainer:last-child {
  border-right: none;
}
// .unitSubContainer .gramValue {
//   width: 50%;
// }
.ant-modal-body .butonGroup {
  width: 100% !important;
}
.proteinBox {
  width: 25%;
}

.unitColor {
  color: #a5a5a5;
}

.proteinText {
  margin-left: 25px;
}
.error {
  color: rgb(172, 11, 11);
}

.loaderWidthHeight {
  width: 608px;
  height: 590px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.supplyIngredientDialogSpanWidth
  > .createIngredientBorderRed
  .ant-select-selector {
  border: 1px solid #ff303c !important;
}

.supplierLabelContainer {
  margin-bottom: 24px;
}

.supplyIngredientDialogSpanWidth .ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
  border-radius: 10px;
  height: 44px;
}

.supplierSelectMargin {
  margin-top: 10px;
}
