@import '../../theme/includes';

.activeStatus {
  background-color: #e5ffe1;
  color: #61c554;
  text-align: center;
  padding: 5px;
}

.notActiveStatus {
  background-color: #ff9494;
  color: #f90101;
  text-align: center;
  padding: 5px;
}
.companyTable .ant-table-cell:nth-child(2) .tableTitle,
.companyTable .ant-table-tbody > tr > td:nth-child(2) > div {
  margin: auto;
  width: 300px;
  justify-content: flex-start;
}

.expiry {
  p {
    margin-bottom: 0;
  }
  span {
    font-size: 10px;
    color: gray;
  }
}
