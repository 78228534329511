.userWizardStepTwo {
  margin-top: 32px;
}
.userWizardStepTwo-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.userWizardStepTwo-heading h3 {
  font-family: 'Sora-SemiBold';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-bottom: 0;
}

.permission-parent-title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
}

.userWizardStepTwo-heading .ant-checkbox + span {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  padding-left: 10.5px;
}

.permissions-block-border {
  border: 1px solid #dedede;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.userWizardStepTwo-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.userWizardStepTwo-content .checkBox-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  max-width: 320px;
  width: 100%;
}
.userWizardStepTwo-content .title {
  font-family: 'Sora-SemiBold';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-bottom: 7px;
}
.userWizardStepTwo-content .ant-checkbox + span {
  padding-left: 10.5px;
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
}
.userWizardStepTwo-content .checkBox-wrapper .toggle {
  width: auto;
  height: auto;
  position: static;
}
.userWizardStepTwo-content .checkBox-wrapper .toggle .ant-switch {
  background: #eeeff2;
  width: 56px;
  height: 32px;
}
.userWizardStepTwo-content .checkBox-wrapper .toggle .ant-switch-checked {
  background: #febd2f !important;
}
.userWizardStepTwo-content
  .checkBox-wrapper
  .toggle
  .ant-switch
  .ant-switch-handle {
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
}
.userWizardStepTwo-content
  .checkBox-wrapper
  .toggle
  .ant-switch-checked
  .ant-switch-handle {
  left: calc(100% - 24px - 2px);
}
.userWizardStepTwo-content
  .checkBox-wrapper
  .toggle
  .ant-switch
  .ant-switch-handle::before {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
.userWizardStepTwo-content .checkBox-wrapper .toggle .ant-switch:focus {
  outline: none;
  box-shadow: none;
}
.userWizardStepTwo-content .checkBox-wrapper .toggle .ant-switch-checked:focus {
  box-shadow: none;
}
