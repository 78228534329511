.report-date-select {
  display: flex;
  align-items: center;
  gap: 20px;
}

.report-container {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2c2c2c;
    font-weight: bold;
  }

  .ant-tabs-ink-bar {
    background-color: #f6a118;
  }

  .ant-tabs-tab:hover {
    color: #f6a118;
  }

  .ant-picker:hover {
    border-color: #f6a118;
  }
}

.report-tabs
  .ant-tabs-top
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 16px 24px;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.report-tabs .ant-tabs-top {
  width: 100%;
}

.report-pills-margin {
  margin-left: 20px;
}

.report-container {
  margin-bottom: 0px;
}

.report-date-select
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 44px;
  border-radius: 10px;
}

.export-report-btn {
  margin-top: 5px;
  margin-right: 25px;
}

.reports-toggle {
  margin-left: 2px;
}

.reports-toggle-label {
  color: grey;
  margin: 12px 10px 0 30px;
}

.reports-bar {
  justify-content: flex-start;
}

.report-divider {
  margin: 5px 0;
}

.reportsDatePicker {
    width: 380px;
    border-radius: 10px;
    height: 40px;
}

.reportsDatePicker:hover,
.reportsDatePicker:focus-within {
    border-color: #f6a118; 
}

.dashboardDatePicker .ant-picker-separator {
  padding: 0 50px !important;
}

.reportsDatePicker .ant-picker-input input:focus, .reportsDatePicker .ant-picker-input input:hover, .ant-picker-input input::selection {
    border-color: #f6a118; 
}

.reportsDatePicker .ant-picker-active-bar {
    border-color: #f6a118;
    background: #f6a118;
    background-color: #f6a118;
}

.reportsDatePicker .ant-picker-header-view button:hover, .reportsDatePicker .ant-picker-header-view button:focus {
  color: grey !important
}

.report-filter {
  gap: 5px;
}

.yellow > .ant-switch-handle::before {
  background-color: #febd2f;
}


