@import '../../theme/includes';
.container {
  padding: 24px;
  background-color: #ffffff;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item-description {
  max-width: 178px;
  white-space: normal;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-family: 'Sora-Regular';
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
  font-family: 'Sora-Regular';
}

.ant-steps-item-title::after {
  position: absolute;
  top: 25px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #d3d0cf;
  content: '';
}

body[dir='rtl'] .ant-steps-item-title::after {
  position: absolute;
  top: 25px;
  right: 136px;
  display: block;
  width: 9999px;
  height: 1px;
  background: #d3d0cf;
  content: '';
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  background-color: #f9ab01;
  padding: 4px 8px;
  border-radius: 50px;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #d3d0cf;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgb(0, 0, 0);
  background-color: #d3d0cf;
  padding: 4px 8px;
  border-radius: 50px;
  padding-top: 3.5px;
}

.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -1px;

  line-height: 1;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  color: #000;
  background: #f9ab01;
  padding: 4px 8px;
  border-radius: 50px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #fff;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #f9ab01;
  border: 1px dashed #f9ab01;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #f9ab01;
}

.ant-steps-item-container {
  outline: none;
  font-family: 'Sora-Regular';
}

.container .ant-steps-item-content {
  margin-top: -9px;
}

.wizard .anticon svg {
  display: inline-block;
  color: #000;
  width: 12px;
  height: 15px;
}
