.subRecipewizardSubContainer {
  width: 100%;
}

.subRecipewizardSubContainer > .row {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
}

.sustainableContainer {
  border: 1px solid #febd2f;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  // background-color: #ffedc7;
}

.subRecipewizardSubContainer > .row > .col {
  width: 272px;
}
.subRecipewizardSubContainer > .row > .sustainableCol {
  width: 260px;
}

.col > .ant-input-lg {
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 10px;
}

.sustainableCol > .ant-input-lg {
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 10px;
}

.col > .subRecipeFinalpricegroup > .ant-input-lg {
  font-size: 14px;
  height: 44px;
}

.col
  > .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 1px 16px;
  font-size: 14px;
  border-radius: 10px;
  height: 46px;
}

.subRecipeIngredient-head {
  align-self: flex-start;
  font-size: medium;
  margin: 32px 0px 24px 0px;
  font-family: 'Sora-SemiBold';
}

.IngredientSubContainer > .subgroup {
  display: flex;
  border: 1px solid #d3d0cf;
  border-radius: 10px;
}

span.category > span {
  background: #f3f4f6;
  width: 100%;
  border: 1px solid #d9d9d9;
  display: block;
  line-height: 44px;
  border-radius: 10px;
  padding-left: 10px;
}
span.sustainabilityIngredient {
  width: 300px;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
}
.sustainabilityIngredientsLabel {
  width: 300px;
}

.wizardIngredientsLabel {
  display: flex;
}

.wizardIngredientsLabel .label {
  width: 70px;
}
.wizardIngredientsLabel .label3 {
  width: 83px;
}
.wizardIngredientsLabel .label:last-child {
  width: 100px;
}
.details {
  width: 70px;
}
.subgroup input {
  border: none;
  border-right: 1px solid #d3d0cf;
  padding: 12px 12px;
}
.subDetails2 {
  width: 100px;
  display: flex;
  align-items: center;
  background: #f3f4f6;
  border-radius: 0 10px 10px 0;
  overflow: hidden;
}

.subDetails2 span {
  width: 50%;
  text-align: center;
  border-right: 1px solid #d3d0cf;
}
.subDetails2 div {
  width: 70%;
  padding-left: 5px;
}
.pricegroup {
  line-height: 44px;
  display: flex;
  align-items: center;
  border: 1px solid #d3d0cf;
  border-radius: 10px;
  overflow: hidden;
}
span.details3 {
  width: 120px;
}
span.details3 .ant-select-selector {
  border: none !important;
  height: 50px !important;
  box-shadow: none !important;
  margin-top: -5px;
}
span.details3 .ant-select-selection-item {
  line-height: 55px !important;
}

span.details3 > .ant-select-lg {
  font-size: 14px;
}

.pricegroup span {
  background: #d3d0cf;
  width: 25%;
  text-align: center;
}
.pricegroup div {
  padding-left: 10px;
  background-color: #f3f4f6;
  width: 75%;
}
.pricegroup input,
.subRecipeFinalpricegroup input {
  border: none;
  outline: none;
}

.pricegroup > .ant-input-lg {
  padding: 11px 16px;
  font-size: 14px;
  border-radius: 10px;
}
.subRecipeFinalpricegroup {
  line-height: 44px;
  border-radius: 10px;
  border: 1px solid #d3d0cf;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.subRecipeFinalpricegroup span {
  width: 25%;
  text-align: center;
  border-right: 1px solid #d3d0cf;
  background: #d3d0cf;
}

.subRecipeInput {
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 10px;
}

.addNewsubDiv {
  width: fit-content;
  cursor: pointer;
}

.ingredientContainerSpace {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.subRecipeDeleteButon {
  cursor: pointer;
}

.dropdownIngredientSubRecipe {
  position: absolute;
  width: 545px;
  background: #ffffff;
  border: 1px solid #d3d0cf;
  box-shadow: 0px 12px 40px 2px rgb(84 84 84 / 12%);
  border-radius: 10px;
  list-style: none;
  padding-left: 0;
  margin-top: 5px;
  margin-bottom: 0;
  z-index: 99999;
}

.dropdownIngredientCreate {
  position: absolute;
  width: 435px;
  background: #ffffff;
  border: 1px solid #d3d0cf;
  box-shadow: 0px 12px 40px 2px rgb(84 84 84 / 12%);
  border-radius: 10px;
  list-style: none;
  padding-left: 0;
  margin-top: 5px;
  margin-bottom: 0;
  z-index: 99999;
}

.borderRed {
  border: 1px solid #ff303c !important;
}

.col > .dropdownUnitColorRed .ant-select-selector {
  border: 1px solid #ff303c !important;
}

.dropdownColorRed .ant-select-selector {
  border: 1px solid #ff303c !important;
}

.sub-recipe-additional-calorie-label {
  font-family: 'Sora-Regular';
  font-size: 14px;
}

.subrecipe-calorie-ingredient-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subrecipe-calorie-ingredient-container .labels-sustainable-container {
  margin-right: -7rem;
  gap: 25px;
}

.sustainable-yield-unit-label {
  width: 100%;
}

.sustainable-gramContainer {
  display: flex;
  align-items: center;
  width: 100%;
  // justify-content: space-between;
  // margin-bottom: 8px;
  position: relative;
}

.sustainable-gramSubContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 272px;
  padding: 12px 0px 12px 0px;
  height: 44px;
}

.sustainable-gramBoxWrapper {
  position: relative;
}

.sustainable-gramBoxWrapper .gramBox {
  padding: 12px 0px 12px 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 106px;
  height: 44px;
  background: #f5f3ef;
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
}

.sustainable-gramSubContainer .ant-input-lg {
  border-radius: 10px 0px 0px 10px;
  width: 166px;
  height: 44px;
}

.sustainable-check-spacing {
  // padding-top: 20px;

  .infoTool {
    background: black;
    color: white;
    font-size: 10px;
    padding: 0.2rem 0.6rem;
    border-radius: 1rem;
  }
}

.error-cat {
  margin: 5px 5px 5px 5px !important;
  position: relative !important;
}
