.uploadImagesForRB .counter {
  font-family: 'Sora-Regular';
  font-size: 10px;
  letter-spacing: -0.02em;
  color: white;
  background-color: #ff303c;
  min-width: 16px;
  border-radius: 100px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadImagesForRB .largeCounter {
  padding: 2px;
}
.uploadImagesForRB .menustyle {
  font-family: 'ClashDisplay-Regular';
}

.uploadImagesForRB
  .uploadImagesWrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child
  .tableTitle {
  justify-content: flex-start;
}

.uploadImagesForRB .uploadImageMenuContainer {
  padding: 28px 24px 16px 24px;
  display: flex;
  gap: 8px;
  position: relative;
}
.uploadImagesForRB .uploadImageMenuContainer .menu {
  margin: 0;
  padding: 0px 7px;
  gap: 4px;
  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  background: #efece6;
  border-radius: 100px;
}
.uploadImagesForRB .uploadImageMenuContainer .menuLight {
  min-width: 160px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0px 7px;
  border: 1px solid #d3d0cf;
  background: white;
  border-radius: 100px;
  transition: all 0.3s;
}
.uploadImagesForRB .uploadImageMenuContainer .menuLight:last-child {
  justify-content: center;
}
.uploadImagesForRB .uploadImageMenuContainer .menuLight:hover {
  cursor: pointer;
  background: #efece6;
  margin-top: -6px;
}
.uploadImagesForRB .uploadImageMenuContainer .text {
  font-family: 'ClashDisplay-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #101010;
}
