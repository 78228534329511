ant-modal-wrap ant-modal-centered {
  border-radius: 16px;
}

.MenuModal {
  margin: 0px 0px 20px 0px;
}

.MenuModal > .menuSubContainer {
  width: 100%;
}
.MenuModal > .ingredientSubContainermargin {
  margin: 0px 0px 24px 0px;
}
.MenuModal > .menuSubContainer .ant-select-lg {
  width: 100%;
  height: 44px;
}

.ant-modal-body::-webkit-scrollbar {
  display: none;
}
.ant-modal-header {
  width: 100%;
  border-radius: 0px;
}

.ant-modal-content {
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}
.ingredientLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}

.menuSubContainer > .ant-input:placeholder-shown {
  text-overflow: ellipsis;
  border-radius: 10px;
  height: 44px;
}

.spanWidth {
  width: 100%;
}

.spanWidth
  > .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 11px;
  border-radius: 10px;
}

.MenuModal
  > .ingredientSubContainer
  > .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.35);
  height: 44px !important;
  width: 100%;
}

.spanWidth > .ant-select-lg {
  font-size: 14px;
}

.unitContainer {
  display: flex;
  align-items: center;
  border: 1px solid #d3d0cf;
  border-radius: 10px;
}

.unitSubContainer {
  display: flex;
  margin: 12px 16px;
  align-items: center;
  justify-content: space-between;
}

.borderContainer {
  border-right: 1px solid #d3d0cf;
  width: 25%;
}

.error {
  color: rgb(172, 11, 11);
}
.menuSubContainer {
  width: 100%;
}

.menuSubContainer > .row {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
}
.menuSubContainer > .row > .col {
  width: 208px !important;
}
.menuSubContainer > .col > .ant-input-lg {
  height: 44px;
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 10px;
}
.menuSubContainer .pricegroup {
  line-height: 43px;
}
.menuSubContainer .pricegroup input {
  width: 75%;
}
span.category > span {
  background: #f3f4f6;
  width: 100%;
  border: 1px solid #d9d9d9;
  display: block;
  line-height: 44px;
  border-radius: 10px;
  padding-left: 10px;
}

.menuSubContainer > .Profitability {
  height: 44px;
  width: 432px;
  left: 0px;
  top: 28px;
  border-radius: 10px;
  padding: 12px 16px 12px 16px;
  background-color: #f3f4f6;
  margin-bottom: 32px;
}

.menuSubContainer
  > .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 44px;
}
.finalMenuPrice .ant-input {
  height: 44px;
  border-radius: 10px;
}

.menu-disable-button {
  background-color: #ffedc7 !important;
  color: #a5a5a5 !important;
}
