.comingSoonDialogContainer .gifWidth {
  width: 400px;
}

.comingSoonDialogContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  margin: auto;
}

.comingSoonDialogContainer .messageText {
  font-family: 'Sora-Regular';
  font-size: 24px;
  margin-bottom: 200px;
}

.comingSoonDialogContainer .info {
  color: #1677ff;
}

.comingSoonDialogContainer .comingSoon {
  color: #febd2f !important;
}
