@import '../../theme/includes';

.recipeStepperContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Sora-Regular';
}

.wizardSubContainer {
  width: 560px;
}

.wizardLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 24px 0px 8px 0px;
}

.plus {
  color: #000;
}

.checkBox {
  margin: 24px;
}

.checkBox > .ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0px 26px 0px 0px;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}

.headingContainer {
  display: flex;
  justify-content: space-between;
}

.Recipe-form-group {
  margin: auto;
  position: relative;
}

.Recipe-form-group label {
  font-family: 'Sora-Regular';
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #212121;
  padding-bottom: 8px;
  display: block;
}
.Recipe-form-group label span {
  color: #f90101;
}
.Recipe-form-group input {
  width: 100%;
  padding: 12px 16px;
  gap: 4px;
  background: #ffffff;
  border: 1px solid #d3d0cf;
  /* box-shadow: 0px 0px 0px 2px rgba(65, 91, 231, 0.2); */
  border-radius: 10px;
  font-size: 14px;
  width: 560px;
  border-radius: 10px;
}

ul.dropdown-list {
  // position: absolute;
  left: 0%;
  right: 0%;
  top: 100%;
  background: #ffffff;
  border: 1px solid #d3d0cf;
  box-shadow: 0px 12px 40px 2px rgba(84, 84, 84, 0.12);
  border-radius: 10px;
  list-style: none;
  padding-left: 0;
  margin-top: 5px;
  margin-bottom: 0;
  z-index: 99999;
}
ul.dropdown-list > li:first-child p {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin: 0;
}
ul.dropdown-list li h3 {
  font-family: 'Sora-SemiBold', 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-bottom: 0;
}
.Recipe-form-group ul.subMenu {
  list-style: none;
  padding-left: 0;
  max-height: 315px;
  min-height: 100px;
  overflow: auto;
}
ul.subMenu li {
  padding: 11px 17px;
}

.placeholder {
  color: #a5a5a5;
  margin-bottom: 0;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
}

ul.subMenu li p {
  margin-bottom: 0;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
}
.subMenu > li {
  position: relative;
}
.list-btn {
  position: absolute;
  right: 23px;
  height: 288px;
  overflow-y: scroll;
  z-index: 999;
  text-align: center;
  padding: 10px;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 24px;
    background-color: #d3d0cf;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }
}
.list-btn p {
  margin-bottom: 0;
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #212121;
  width: 20px;
}
.list-btn .active {
  background: #f5f3ef;
  border-radius: 100%;
}
li.create-category {
  position: relative;
  height: 42px;
}

li.create-category button {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: none;
  background: #000;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}
.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  background-color: #212121;
}

.buttonStyling {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  background-color: #212121;
  border: none;
}

.optionIcon {
  padding-left: 10px;
}

.modeTwo {
  justify-content: space-between;
}

.mainWrapper {
  margin-top: 20px;
}

.selectStyle {
  width: 500px;
}

.dividerStyle {
  margin: 4px 0;
}
.drop-down-wrapper {
  position: relative;
  z-index: 9999;
  margin-bottom: 10px;
}

.drop-down-wrapper:hover .wrapper-btn {
  opacity: 1;
  background: #fff;
  border: none;
}

.wrapper-btn {
  position: absolute;
  right: -25px;
  top: 11px;
  opacity: 0;
  z-index: 9999;
}
.drop-down {
  border: 1px solid #d3d0cf;
  border-radius: 10px;
  overflow: hidden;
}

.drop-down-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: #f3f4f6;
  // border-radius: 10px 10px 0 0;
}

.drop-down-header p {
  margin-bottom: 0;
}

.drop-down-header button {
  background: none;
  border: none;
}

.drop-down-content {
  padding: 16px;
}
.drop-down-content .form-group {
  max-width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
}

.drop-down-content .form-group input {
  width: 88%;
  border-radius: 10px;
  height: 44px;
  border-right: 1px solid #d3d0cf;
}

.form-group span {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-group:hover button {
  opacity: 1;
}
.form-group button {
  opacity: 0;
  background: white;
  border: none;
}
.drop-down-content .addItem {
  color: #212121;
}
.wizardSubContainer:has(.Recipe-form-group) {
  width: 560px;
}
.Recipe-form-group ul.dropdown-list {
  top: 48px;
  margin-top: 0 !important;
}
.Recipe-form-group .ant-input {
  margin-bottom: 5px;
}
