.gridIconContainer {
  display: flex;
  border: 1px solid #d3d0cf;
  border-radius: 50px;
  width: 88px;
  height: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gridIcon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 12px;
  gap: 8px;
  left: 50%;
  right: 4.55%;
  top: 8.33%;
  bottom: 8.33%;
  border-radius: 24px;
}

.gridActive {
  background-color: #febd2f;
  margin: 5px;
}
