.Brands-list-wrapper
  .ant-table-tbody
  > tr
  > td:first-child {
    width: 35px !important;
  }

.cash-purchase-wizard-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Sora-Regular';
  max-width: 820px !important;
  margin: auto;
}

.cp-wizard-container {
  min-height: 410px !important;
}

.cp-wizardSubContainer > .ant-input-lg {
  width: 560px !important;
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 10px;
  height: 44px;
}

.cash-purchase-unit-cost-input {
  border: 1px solid #f5f3ef;
  padding: 8px;
  width: 70px;
  border-radius: 10px;
}

.cash-purchase-net-cost-input {
  width: 130px !important;
}

.costExclamationIcon {
  color: grey;
  margin-left: 3px;
}

.add-label-qty {
  margin-right: 3rem;
}

.add-label-uom {
  margin-right: 4.5rem;
}

.cp-ingredient-container {
  display: flex;
  place-content: center;
}

.cp-del-img {
  position: relative !important;
}

.cp-dropdown {
  width: 820px !important
}

.cp-label-container {
  margin-right: 5rem !important
}

.col-input {
  width: 95px !important;
}


