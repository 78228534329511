.inventoryFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  z-index: 2000;
}

// .inventoryFilter-select {
//   width: 200px;
// }

.inventoryFilter-Radio .ant-radio-button-wrapper:hover {
  position: relative;
  color: #f9ab01;
}

.inventoryFilter-Radio .ant-radio-button-wrapper {
  width: 88px;
  height: 35px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  border-color: #f9ab01;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #f9ab01;
  border-color: #f9ab01;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #f9ab01;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper {
  border-right-color: #f9ab01 !important;
  border-left-color: #f9ab01 !important;
}

.inventoryFilter-Apply-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.inventoryFilter-Apply {
  display: flex;
  padding: 12px 16px;
  width: fit-content;
  height: 40px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f9ab01;
  transition: background-color 0.3s ease;
}

.inventoryFilter-Apply:hover {
  background-color: #fedd94;
}

.reset-filter-button {
  display: flex;
  padding: 12px 16px;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #212121;
  transition: background-color 0.3s ease;
}

.reset-filter-button:hover {
  background-color: #f5f5f5;
}

.filters {
  margin-top: 10px;
  margin-bottom: 5px;
  justify-content: flex-start !important;
}

.ingredient-type {
  margin-right: 5px;
}

.reset-btn {
  margin-top: 20px;
}
