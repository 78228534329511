.success {
  color: green;
  margin-bottom: 10px;
  margin-top: 50px;
}

.b {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.authContainerForConfirmEmail {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  color: red;
  margin-bottom: 10px;
  margin-top: 20px;
}

.leftSideForConfirmEmail {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-shadow: 0 0 10px 5px #ebebeb;
  border-radius: 20px;

  .sideLogoContainer {
    display: flex;
    justify-content: center;
    
    .sideLogo {
      width: 50%;
      margin: 2rem 0;
    }
  }
}

.authContainerForConfirmEmail .ant-form label {
  text-transform: capitalize;
  font-size: 18px;
  margin-top: 10px;
}

.authContainerForConfirmEmail .alignment {
  display: flex;
  margin: 2rem 0rem 4rem 0;
}
