.userWizardStepOne {
  max-width: 560px;
  margin: auto;
}
.passwordValidation {
  font-family: 'Sora-Regular';
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #ff303c;
  width: 100%;
  margin-top: 8px;
}

.wizardDropdown .ant-select-multiple .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  min-height: 44px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.35);
}

.wizardDropdown .ant-select-single .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  min-height: 44px;
  padding-top: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.35);
}

.wizardSubContainer .ant-input-group-wrapper .ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  height: 44px;
}

.user-company {
  border-radius: 10px;
}

body[dir='rtl']
  .wizardSubContainer
  .ant-input-group-wrapper
  .ant-input-group
  .ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  border-radius: 0px 10px 10px 0px;
}

body[dir='rtl']
  .wizardSubContainer
  .ant-input-group-wrapper
  .ant-input-group
  .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
  height: 44px;
  border-radius: 10px 0px 0px 10px;
}

.wizardSubContainer .ant-input-group-wrapper .ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
  height: 44px;
  border-radius: 0px 10px 10px 0px;
}

.wizardSubContainer
  .ant-input-group-wrapper
  .ant-input-group
  .ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  border-radius: 10px 0px 0px 10px;
}

.wizardDropdown .userRole > span {
  background: #f3f4f6;
  width: 100%;
  border: 1px solid #d9d9d9;
  display: block;
  line-height: 44px;
  border-radius: 10px;
  padding-left: 10px;
}

.user-change-password-text {
  font-size: 12px;
  text-align: end;
  margin-top: 5px;
  color: #febd2f;
  cursor: pointer;
}
