@import '../../theme/includes.scss';

.list-count {
  width: 325px;
  display: flex;
}

.list-count .ant-row {
  width: inherit;
}

.list-count h1 {
  margin-bottom: 4px;
}

.list-count div {
  background-color: transparent;
}
