.wizardDropdownStep3 {
  width: 100%;
}

.wizardDropdownStep3 .ant-input {
  box-sizing: border-box;
  margin: 0;

  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;

  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;

  border: 1px solid #d9d9d9;

  transition: all 0.3s;
  padding: 12px 16px;

  height: 44px;
  background: #ffffff;

  border-radius: 10px;
}

.wizardDropdownStep3 .ant-input:focus-visible {
  outline: none;
  border: 1px solid #f9ab01;
}

.stepThreeInput {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px;
}

.stepThreeTextArea .ant-input {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
  padding: 12px 16px;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 32px;
}

.wizardDropdownStep3 .ant-input:focus,
.ant-input-focused {
  border-color: #f9ab01;
  box-shadow: 0 0 0 0 rgb(255, 255, 255);
  border-right-width: 1px;
  outline: 0;
}

.wizardLabelAdvance {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}

.step3-head {
  margin: 24px 0px;
  width: 100%;
  font-family: 'Sora-SemiBold';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
}

.BottomSpacing {
  margin-bottom: 30px;
}

.step3-subHead {
  margin-bottom: 24px;
  width: 100%;
  font-family: 'Sora-SemiBold';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
}

.PracticeContainer {
  width: 560px;
  margin: 0px 0px 24px 0px;
}

.PracticeContainerJustify {
  display: flex;
  gap: 16px;
  align-items: center;
}

.subContainerPractice {
  width: 272px;
}

.subContainerPractice
  > .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 11px;
  border-radius: 10px;
}

.subContainerPractice > .ant-select-lg {
  font-size: 14px;
}

.PracticeLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}
.allergens-content {
  width: 100%;
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.allergens-content li {
  width: 25%;
  margin-bottom: 18px;
  display: flex;
  gap: 10px;
  color: #212121;
  align-items: center;
}

.checkboxIngredient {
  width: 16px;
  height: 16px;
  border-radius: 3px;
}

.stepThreeSubContainer {
  width: 560px;
  margin: 0px 0px 24px 0px;
}

.ingredientLabel {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 0px 0px 8px 0px;
}

.stepThreeSubContainer > .ant-input:placeholder-shown {
  text-overflow: ellipsis;
  border-radius: 10px;
  height: 44px;
}

.stepThreeadditonal {
  display: flex;
  align-items: center;
}
.stepThreeadditonal > div {
  width: 25%;
}
.stepThreeunitContainer {
  display: flex;
  align-items: center;
  border: 1px solid #d3d0cf;
  border-radius: 10px;
}

.stepThreeunitSubContainer {
  display: flex;
  margin: 12px 16px;
  align-items: center;
  justify-content: space-between;
}

.stepThreeborderContainer {
  border-right: 1px solid #d3d0cf;
  width: 25%;
}
.stepThreeunitContainer .borderContainer:last-child {
  border-right: none;
}
.stepThreeunitSubContainer .gramValue {
  width: 50%;
}
.stepThreeproteinBox {
  width: 25%;
}

.unitColor {
  color: #a5a5a5;
}

.proteinText {
  margin-left: 25px;
}

input[type='checkBox'] {
  accent-color: #212121;
}

.allergens {
  margin-bottom: 12px;
}

.loadIngredient {
  height: 100px;
  display: flex;
  align-items: center;
}

.addtionalAttributeLabel {
  font-size: 14px;
}
