@import '../../theme/includes.scss';

.app-logo {
  padding: 15px 10px 40px 10px;
}
.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding-top: 20px;
  width: var(--page-header-width);
  background: $themeSidebarColor;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.page-header::-webkit-scrollbar {
  display: none;
}

.page-header {
  nav {
    display: flex;
    flex-direction: column;
    min-height: 90%;
  }
}

.page-header .feedBackButton {
  text-align: center;
}
.page-header .feedBackButton .ant-btn {
  height: 33px;

  background-color: #febd2f;
  font-family: 'ClashDisplay-Medium';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.page-header .feedBackButton .ant-btn:not([disabled]):hover {
  text-decoration: none;
  background-color: none;
}
.page-header .feedBackButton .ant-btn:not([disabled]):focus {
  text-decoration: none;
  border: none;
}
.page-header {
  .admin-menu {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 35px;
  }
}
.page-header .admin-menu li > * {
  width: 100%;
  padding: 12px 15px;
}
.page-header {
  .admin-menu {
    .menu-heading {
      h3 {
        text-transform: uppercase;
        letter-spacing: 0.15em;
        font-size: 12px;
        margin-top: 12px;
        color: $pageheaderHeadingColor;
      }
    }
  }
}
.page-header .admin-menu a,
.page-header .admin-menu button {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
.page-header .admin-menu a:hover,
.page-header .admin-menu a:focus,
.page-header .admin-menu button:hover,
.page-header .admin-menu button:focus {
  color: $black;
  outline: none;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  transition: 0.5s;
  outline: none;
}
/* ------------ */

.card {
  overflow: hidden;
  padding: 0px 20px;
}
.rc-accordion-toggle {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  justify-content: space-between;
  transition: 0.3s;
}
.rc-accordion-toggle.active .rc-accordion-icon {
  transform: rotate(180deg);
  color: $black;
}
.rc-accordion-card {
  margin-bottom: 5px;
  overflow: hidden;
}
.rc-accordion-card:last-child {
  margin-bottom: 0;
}
.rc-accordion-title {
  margin-left: 10px;
  display: inline;
  font-weight: 600;
  font-size: 14px;
  position: relative;
  margin-bottom: 0;
  font-family: 'ClashDisplay-Regular';
  color: $themeDarkText;
  transition: 0.3s;
  cursor: pointer;
}
body[dir='rtl'] .rc-accordion-title {
  margin-right: 10px;
  margin-left: 0;
}
.rc-accordion-subtitle {
  margin-left: 10px;
  display: inline;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  margin-bottom: 0;
  font-family: 'ClashDisplay-Regular';
  color: $themeDarkText;
  transition: 0.3s;
  cursor: pointer;
}
body[dir='rtl'] .rc-accordion-subtitle {
  margin-right: 10px;
  margin-left: 0;
}
.activeMenuItem {
  padding: 8px 10px;
  background: #febd2f;
  border-radius: 4px;
}

.vertical-line:before {
  content: '';
  position: absolute;
  border-left: 1px solid $themeLightText;
  left: -15px;
  bottom: -20px;
  z-index: -1;
  height: 55px;
}
body[dir='rtl'] .vertical-line:before {
  right: -15px;
  left: auto;
}
.rc-accordion-toggle.active .rc-accordion-title .rc-accordion-subtitle {
  color: $black;
}
.rc-accordion-icon,
.common-icon {
  position: relative;
  top: 2px;
  color: #475f7b;
  transition: 0.35s;
  font-size: 12px;
}
.rc-accordion-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px 8px;
}
.rc-collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease;
  margin: 10px 5px;
}
.rc-collapse.show {
  height: auto;
}
.header-bottom {
  border-top: 1px solid $borderColor;
  padding: 10px 0px;
  color: $black;
  width: var(--page-header-width);
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-width: 1px;
  position: absolute;
  bottom: 0px;
}
.bottom-user-detail-container {
  margin: 0px 40px 0px 10px;
}
.header-bottom-user-image {
  width: 30px;
  height: 30px;
  border-radius: 360;
  border-width: 1px;
}
.carret-button-bottom {
  cursor: pointer;
}

.sidebar-icon {
 padding-top: 8px;
}

.beta-features {
  padding-left: 8px;
  padding-bottom: 10px;
}

.beta-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.beta-header img {
  margin-bottom: 25px;
}

.help-icon {
  position: fixed !important;
  bottom: 30px !important;
  right: 20px !important;
  z-index: 9999 !important;
}
