.OrderListDialogContainer .ant-modal-content .ant-modal-body {
  height: 340px;
}

.marginBottomConfirmationText {
  margin: 0px 0px 16px 0px;
}

.order-list-dialog-body-heading {
  margin-bottom: 8px;
  font-family: 'Sora-SemiBold';
}

.gramContainer {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-bottom: 8px;
  position: relative;
}

.errorMessage-container {
  font-family: 'Sora-Regular';
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #ff303c;
  width: 100%;
  margin: 5px;
  // position: absolute;
  // left: 283px;
  // bottom: -20px;
}

.orderListWidthDialogContainer .ant-modal-content {
  width: 620px;
}

.orderListWidthDialogContainer .ant-modal-content .ant-modal-body {
  width: 620px !important;
}

.productionPlan-gramSubContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: 200px;
  // padding: 12px 0px 12px 16px;
  height: 44px;
  border: 1px solid #d3d0cf;
  border-radius: 0px 10px 10px 0px;
}

.productionPlan-gramBox {
  padding: 12px 0px 12px 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 106px;
  height: 42px;
  background: #f5f3ef;
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
}
.productionPlan-gramBoxWrapper {
  position: relative;
}

.productionPlan-gramValue {
  border: none;
  width: 100px;
  padding: 12px;
}

.productionPlan-gramSubContainer input:first-child {
  border-right: 1px solid #dedede;
}

.productionPlan-modal-ingredient-container {
  display: flex;
  align-items: center;
}

.productionPlan-ingredient-label {
  width: 264px;
}

.productionPlan-required-label {
  width: 102px;
}
