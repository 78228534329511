@import '../../theme/includes.scss';
.Container .uploadedimage {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  margin-bottom: 30px;
}

.Container .uploadRecipeImgWrapperStepTwo {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 224px;
}
.Label {
  width: 601px;
  font-family: 'Sora-SemiBold';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
}
.uploadRecipeImgWrapperStepTwo img {
  border-radius: 8px;
}
.text {
  display: inline-grid;
  font-family: 'Sora-SemiBold';
  font-size: 16px;
  line-height: 24px;
}
.textSizeGap {
  margin-bottom: 8px;
}
.Container .deletebutton {
  margin-left: 20px;
  display: inline;
}
.Subcontainer {
  height: 650px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  margin-bottom: 20px;
}
.tagDropdown {
  margin-left: 36px;
}
.tagDropdown
  > .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 297px;
  height: 44px;
  border-radius: 10px;
  align-items: center;
}

.uploadImageSize {
  font-family: 'Sora-Regular';
  font-size: 14px;
  letter-spacing: -0.02em;
  color: $themeLightText !important;
}
