.title-recipe {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-left: 10px;
}

.subModelBody {
  max-height: 480px;
  overflow-x: hidden;
}

.subModelBody::-webkit-scrollbar {
  width: 4px;
  background-color: #d3d0cf;
  height: 10vh;
}

/* Track */
.subModelBody::-webkit-scrollbar-track {
  width: 4px;
  height: 10vh;
}

/* Handle */
.subModelBody::-webkit-scrollbar-thumb {
  width: 4px;
}

.titleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.SubDialogWrapper .anticon svg {
  display: inline-block;
  color: #d3d0cf;
}

.image-recipe {
  margin-bottom: 24px;
}
.subhead-recipe {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cover-photo-recipe {
  position: relative;
}

.subHead-left-recipe {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  letter-spacing: -0.02em;
  color: #101010;
}

.subHead-right-recipe {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin-left: 7px;
}

.block-recipe {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 253px;
  height: 25px;
}
.block1-recipe {
  gap: 24px;
}
.block2-recipe {
  gap: 22px;
}

.protein-recipe {
  margin-left: 16px;
}

.ingredient-recipe {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #a5a5a5;
}

.block3-recipe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0px 8px 0px;
}

.block4-recipe {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block4-text-recipe {
  width: 280px;
  height: 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  margin: 2px 0px;
}

.block4-weight-recipe {
  width: 35px;
  height: 20px;
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
}
.ingredient-text-recipe {
  height: 20px;
}

.callories-text-recipe {
  width: 46px;
  height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.arrowBack {
  cursor: 'pointer';
}
