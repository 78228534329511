.orderTypeDialogContainer {
  width: 300px;
  margin-bottom: 1rem;
}

.orderTypeModal {
  width: auto !important;
}

.ordertype-btn {
  padding-top: 10px !important;
}
