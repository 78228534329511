@import '../../theme/includes';

.wizardContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 700px;
  font-family: 'Sora-Regular';
  max-width: 560px;
  margin: auto;
}

.IngredientSubContainer > ul.subMenu {
  list-style: none;
  padding-left: 0;
  max-height: 260px;
  min-height: 150px;
  overflow: auto;
}
