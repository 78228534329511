.card-block::-webkit-scrollbar {
  display: none;
}
.card-block {
  padding: 0px 24px;
  height: 684px;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card {
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}
.card .ant-card-cover {
  margin: 0 -20px;
}
.card .ant-card-body {
  padding: 16px 0;
}
.dots {
  width: fit-content;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f4f7;
  border-radius: 50px;
  cursor: pointer;
}

.variationTag {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  left: 16px;
  top: 206px;
  margin-top: 8px;
  margin-bottom: 12px;
}

.variationText {
  font-family: 'Sora-Regular';
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #999999;
}

.thumbnail {
  width: 100%;
  // object-fit: contain;
  // margin-right: 20px;
}

.subTextCol1 {
  display: flex;
  align-items: center;
}
// .subCol1Icon {
//   padding: 5px;
//   border: 1px solid #d3d0cf;
//   border-radius: 50px;
//   margin-right: 8px;
//   width: 32px;
//   height: 32px;
// }

.iconFireWhite {
  padding: 5px;
  border: 2px solid #04cc00;
  border-radius: 50px;
  margin-right: 8px;
  width: 32px;
  height: 32px;
}
.calories {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Sora-Regular';
  color: #a5a5a5;
  margin-left: 2px;
}
.font {
  font-family: 'Sora-SemiBold';
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.head .col1Text.font {
  color: #101010;
}
.variationOption .ant-select-selector {
  padding: 0 !important;
  gap: 12px;
}
.variationOption .ant-select {
  width: auto !important;
}
.variationOption .ant-select-selection-item {
  color: #212121;
  padding-right: 0;
}
.subTextCol1 span.col1Text.font {
  color: #212121;
}
.bottom .bottom .itemGap,
.bottom .bottom {
  color: #212121;
}
.bottomForRecipe {
  display: flex;
  justify-content: space-between;
}

.rupeesTag {
  font-family: 'Sora-SemiBold' !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: right;
  color: orange !important;
  // color: #3430ff;
}

.bottom {
  display: flex;
  align-items: center;
}
.gap {
  gap: 6px;
}

// .col1Text {
//   font-weight: 700;
//   cursor: pointer;
//   font-size: 16px;
//   line-height: 24px;
// }

.lineHeight {
  margin-top: 8px;
}

.grid-menu-price-line-height {
  margin-top: 12px;
}

.itemGap {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.percentagePrice {
  font-family: 'Sora-Regular';
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: #a5a5a5;
  margin-left: 0px;
  font-weight: 600;
  cursor: pointer;
}

.imgtag {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 16px;
  gap: 10px;
  position: absolute;
  height: 28px;
  left: 16px;
  top: 16px;
  background: #f5f3ef;
  border-radius: 100px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Sora-Regular';
  color: #101010;
}

.cover {
  position: relative;
  cursor: pointer;
  height: 190px;
}

.coverWithoutHover {
  position: relative;
  // width: 272px;
  height: 190px;
}

.card-block .toggle .ant-switch-checked {
  width: 48px;
  height: 28px;
  background: #f0fff0 !important;
}

.card-block .toggle .ant-switch {
  background: #fff0f1;
  width: 48px;
  height: 28px;
}

.card-block .toggle .ant-switch .ant-switch-handle {
  width: 22px;
  height: 22px;
  top: 50%;
  transform: translateY(-50%);
}

.card-block .toggle .ant-switch .ant-switch-handle::before {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.cover:after {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  border-radius: 20px 20px 0px 0px;
}
.cover:hover:after {
  opacity: 1;
}

.tooltipHead {
  font-family: 'Sora-SemiBold', 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-radius: 20px;
  letter-spacing: -0.02em;

  color: #ffffff;
}

.tootipText {
  font-family: 'Sora-SemiBold', 'Sora';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;

  color: #ffffff;
}

.icons {
  cursor: pointer;
  margin-right: 4px;
}

.button {
  position: absolute;
  top: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 12px 16px;
  z-index: 1;
}

.buttonHide {
  display: none;
}

.buttonText {
  color: white;
  padding: 12px 16px;
  border: 1px solid white;
  border-radius: 50px;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  outline: none;
  transition: all 0.3s;
}

.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-pagination-item-active a {
  /* color: #1890ff; */
  background-color: #febd2f;
  border-radius: 11px;
}

.ant-pagination-item:hover a {
  color: #000;
}

.ant-pagination-item:hover {
  border-color: #febd2f;
  transition: all 0.3s;
}

.ant-pagination-item-active a {
  color: rgb(0, 0, 0);
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #febd2f;
}

.dropdownIcon {
  width: 15px;
  height: 20px;
  margin-left: -3px;
}

.dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 13px;
  width: 60px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 8px;
}

.dropdownValue {
  padding: 6px 6px;
}

.showText {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #a5a5a5;
}

.total {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-weight: 700;
  color: #212121;
}

.count {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #212121;
}

.dropdownContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 24px;
}
.ant-switch {
  background-color: #f5f3ef;
}
.toggle {
  z-index: 1;
  position: absolute;
  top: 16px;
  right: 24px;
  width: 48px;
  height: 28px;
  cursor: pointer;
}

.edit {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'ClashDisplay-Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.card .ant-card-body .ant-divider-horizontal {
  margin: 12px 0 14px 0px;
}

// .edit img {
//   margin-right: 10px;
// }

.edit span {
  margin-left: 8px;
}

.green > .ant-switch-handle::before {
  background-color: #04cc00;
}
.red > .ant-switch-handle::before {
  background-color: #ff303c;
}

.recipeBuilder-grid-view-calorie {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actual-price-menu {
  justify-content: center;
}

.sample-check {
  margin-top: 10px;
}

.sample-font {
  font-size: 16px;
}
