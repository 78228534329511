.feedbackViewContainer {
  height: 504px;
  overflow-y: scroll;
}

.feedbackViewContainer .feedbackViewImage {
  width: 512px;
  height: 280px;
  border-radius: 8px;
}

.feedbackViewContainer .feedbackViewIssueType {
  width: fit-content;
  height: 36px;
  background: #f5f3ef;
  border-radius: 100px;
  padding: 8px 16px;
  text-align: center;
  margin: 16px 0px 8px 0px;
  font-size: 14px;
  font-family: 'Sora-Regular';
  letter-spacing: -0.02em;
}

.feedbackViewContainer .feedbackViewScreen {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #101010;
  font-family: 'Sora-SemiBold';
}

.feedbackViewContainer .feedbackViewDescription {
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  font-family: 'Sora-Regular';
  margin-top: 8px;
}
