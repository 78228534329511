.ant-table-thead .ant-table-cell {
  color: #a5a5a5;
  background-color: #ebebeb;
}

// .statusContainerActive {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   padding: 8px 12px;
//   gap: 12px;
//   cursor: pointer;
//   border-radius: 100px;
//   width: fit-content;
//   background-color: #e5ffe1;
//   color: #61c554;
// }

// .statusContainerNonActive {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   padding: 8px 12px;
//   gap: 12px;
//   cursor: pointer;
//   border-radius: 100px;
//   width: fit-content;
//   background-color: #ffe1e1;
//   color: #f90101;
// }

.companyLight {
  margin: 0px 10px 0px 0px;
}

.id {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-family: 'Sora-Regular';
}

.name {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #101010;
  font-family: 'Sora-SemiBold';
}

.email {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #a5a5a5;
  font-family: 'Sora-Regular';
}

// .companyContainer {
//   display: flex;
//   align-items: center;
//   gap: 10px;
// }

.company {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #101010;
  font-family: 'Sora-Regular';
}

.status {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  font-family: 'Sora-Regular';
}

.iconDropdown {
  width: 20px;
  height: 20px;
}

.ant-dropdown-menu {
  // width: 153px;
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 12px 9px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}

.country {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-family: 'Sora-Regular';
}

.city {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-family: 'Sora-Regular';
}

.deleteText {
  color: #ab2626;
}
