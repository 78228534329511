@import '../../theme/includes';

.recipePage .card-block {
  padding-top: 16px;
}

// .created-at-first-column {
//   margin: 3px;
// }

// .col2 {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   padding: 8px 16px;
//   gap: 10px;
//   cursor: pointer;
//   width: fit-content;
//   height: 36px;
//   left: 321px;
//   top: 28px;
//   background: #f5f3ef;
//   border-radius: 80px;
// }

.collapseWeight {
  width: 110px;
  height: 20px;
  font-family: 'Sora-Regular', 'Sora';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #212121;
  text-align: end;
}
