.alignment {
  display: flex;
  align-content: center;
  margin-bottom: 20px;
}

.resetContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;

  .ant-form label {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.success {
  color: green !important;
  margin-top: 20px;
}

.leftSideForResetPassword {
  display: flex;
  flex-direction: column;
  padding: 0;
  box-shadow: 0 0 10px 5px #ebebeb;
  border-radius: 20px;

  .ant-form-item {
    margin-bottom: 10px;
  }
  .sideLogoContainer {
    display: flex;
    justify-content: center;
    
    .sideLogo {
      width: 50%;
      margin: 2rem 0;
    }
  }
}

.error {
  color: red;
  margin-top: 20px;
}

.b {
  font-family: 'Sora-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
