.minimumheight {
  min-height: 350px;
}

.BranchTextArea {
  height: auto !important;
}

.wizardSubContainer > .dropdownUnitColorRed .ant-select-selector {
  border: 1px solid #ff303c !important;
}

.spacingForButtonGroup {
  margin-top: 52px;
}

.supplierWizard .supplierCollapseContainer {
  width: 100%;
  margin: 10px;
}

.ant-picker-footer .ant-btn-primary {
  color: #fff;
  border-color: #febd2f;
  background: #febd2f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.shopDaysContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time-error {
  color: red; 
  font-size: 12px;
}
